import { Component, Input, Output, EventEmitter, ViewChild, forwardRef, ChangeDetectorRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickDialogComponent } from "../slick-dialog/slick-dialog.component";
import { SlickEmailerComponent } from "./slick-emailer.component";
import { ISlickFileModel } from "../slick-file-model/slick-file.model";
import { ISlickEmailerModel, } from "./slick-emailer.model";
import { ISlickEmailerAddressModel } from "./slick-emailer-address.model";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";

declare const tinymce: any;

@Component({
	selector: 'slick-emailer-dialog',
	template:
	`<slick-dialog *ngIf='isVisible' header='Send Email' [width]='800' (onClose)='onCancelEmail()' #emailerDialogRef>
		<slick-emailer #emailerRef [(ngModel)]='dialogEmailerModel'
					[bodyHeight]='bodyHeight'
					[showButtons]='false'
					[toEmailAddressList]='toEmailAddressList' 
			        (toAddressSearch)='toAddressSearch($event)'>
		</slick-emailer>

		<slick-dialog-footer>
			<div class="row">
				<div class="col-12 text-center mb-2 mt-1">
					<button type="button" style="width: 120px; margin-right: 10px;" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-primary" (click)="onSendEmail()"><i class="far fa-envelope"></i>&nbsp;Send</button>
					<button type="button" style="width: 120px;" class="btn btn-outline-danger" (click)="onCancelEmail()"><i class="far fa-times"></i>&nbsp;Cancel</button>
				</div>
			</div>
		</slick-dialog-footer>
	</slick-dialog>`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickEmailerDialogComponent),
			multi: true,
		}]
})
export class SlickEmailerDialogComponent {
	@Input("toEmailAddressList") toEmailAddressList: ISlickEmailerAddressModel[];
	@Input("bodyHeight") bodyHeight: string = "180px";
	@Output("toAddressSearch") toAddressSearchEmitter: EventEmitter<string> = new EventEmitter();
	@Output("sendEmailClick") sendEmailClickEmitter: EventEmitter<ISlickEmailerModel> = new EventEmitter();
	@Output("cancelEmailClick") cancelEmailClickEmitter: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("emailerDialogRef") emailerDialogRef: SlickDialogComponent;
	@ViewChild("emailerRef") emailerRef: SlickEmailerComponent;

	dialogEmailerModel: ISlickEmailerModel;
	emailerModel: ISlickEmailerModel;
	spinnerStatus: string = null;
	isVisible = false;

	constructor(private changeDetector: ChangeDetectorRef) { }

	propagateChange = (_: ISlickEmailerModel) => { };

	// this is the initial value set to the component
	public writeValue(obj: ISlickEmailerModel) {
		this.emailerModel = obj;
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

	async showDialog() {
		this.isVisible = true;

		await SlickSleepService.sleep();

		this.dialogEmailerModel = this.emailerModel;
		this.emailerRef.isSubmitted = false;
		this.spinnerStatus = "reset";
		this.emailerRef.dialogInit();
		this.emailerDialogRef.showDialog();
	}

	toAddressSearch(searchText) {
		this.toAddressSearchEmitter.emit(searchText);
	}

	onSendEmail() {
		this.dialogEmailerModel.toEmailAddresses = this.emailerRef.getEmailAddresses(this.dialogEmailerModel);
		if (this.emailerRef.validate() === false)
			return;

		this.dialogEmailerModel.body = this.emailerRef.getBody();
		this.spinnerStatus = "spin";

		if (this.sendEmailClickEmitter)
			this.sendEmailClickEmitter.emit(this.dialogEmailerModel);
	}

	async emailSent() {
		try {
			this.spinnerStatus = "ok";
			this.emailerRef.emailSent();
			this.emailerDialogRef.hideDialog();
			await SlickSleepService.sleep(500);
			this.emailerRef.removeTinyMCE();
		}
		catch (error) {
			console.error(error);
			this.spinnerStatus = "error";
		}

	}

	emailError() {
		this.spinnerStatus = "error";
	}

	async onCancelEmail() {
		this.spinnerStatus = "reset";
		this.emailerRef.removeTinyMCE();
		this.emailerDialogRef.hideDialog();
		if (this.cancelEmailClickEmitter)
			this.cancelEmailClickEmitter.emit();
	}
}
