import { IItemAssemblyModel } from "./item-assembly.model";

export interface IItemModel {
	itemId: number;
	sku: string;
	description: string;
	shortDescription: string;
	barCode: string;
	inventoryType?: number;
	cost: number;
	price: number;
	favorite: boolean;
	useKeypadQuantity: boolean;
	active: boolean;
	imageId?: number;
	imageUrl: string;
	imageThumbnailUrl: string;
	lockedCost: boolean;
	isGlobalItem: boolean;
	coverstarItem: boolean;
	pcsItem: boolean;
	purchasable: boolean;
	visibleToChildren: boolean;
	editable: boolean;
	syncToQuickbooks?: boolean;
	quickbooksId: string;
	quickbooksCategoryId: string;
	quickbooksIncomeAccountId: string;
	quickbooksSyncStatus: string;
	syncToBusinessCentral?: boolean;
	businessCentralId: string;
	upSell: boolean;
	defaultIsTaxed: boolean;
	isParentItem: boolean;

	itemAssemblies: IItemAssemblyModel[];
}

export class ItemModel implements IItemModel {
	constructor() {
		this.itemId = 0;
		this.active = true;
		this.cost = 0;
		this.coverstarItem = true;
		this.description = 'New Item';
		this.editable = true;
		this.favorite = false;
		this.isGlobalItem = false;
		this.lockedCost = false;
		this.pcsItem = false;
		this.price = 0;
		this.purchasable = false;
		this.sku = 'SKU';
		this.syncToQuickbooks = false;
		this.useKeypadQuantity = false;
		this.visibleToChildren = false;
		this.defaultIsTaxed = true;
		this.quickbooksSyncStatus = "Not Synced";
		this.isParentItem = false;
	}

	itemId: number;
	sku: string;
	description: string;
	shortDescription: string;
	barCode: string;
	inventoryType?: number;
	cost: number;
	price: number;
	favorite: boolean;
	useKeypadQuantity: boolean;
	active: boolean;
	imageId?: number;
	imageUrl: string;
	imageThumbnailUrl: string;
	lockedCost: boolean;
	isGlobalItem: boolean;
	coverstarItem: boolean;
	pcsItem: boolean;
	purchasable: boolean;
	visibleToChildren: boolean;
	editable: boolean;
	syncToQuickbooks?: boolean;
	quickbooksId: string;
	quickbooksCategoryId: string;
	quickbooksIncomeAccountId: string;
	quickbooksSyncStatus: string;
	syncToBusinessCentral?: boolean;
	businessCentralId: string;
	upSell: boolean;
	defaultIsTaxed: boolean;
	isParentItem: boolean;

	itemAssemblies: IItemAssemblyModel[];
}
