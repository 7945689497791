import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { DropdownModel, IAppointmentStatusModel, IAppointmentTypeModel, IChecklistQuestionModel, ICustomerAreaModel, ICustomCustomerFieldModel, ICustomerTypeModel, IDiscountModel, IDropdownModel, IEmailerAddressModel, IFuelSurchargeModel, IInvoiceLaborItemModel, IInvoiceStatusModel, IItemGroupingModel, IJobStatusModel, IJobTypeModel, ILathamCarrierModel, ILathamDistributorModel, IMaintenanceReadingItemModel, ILocationModel, IPaymentTermModel, IPriceLevelModel, IQuickAddCategoryModel, IRebateModel, IResourceModel, IServiceAreaModel, ISmartlistQuestionModel, ITaxRegionModel, IUserModel, ITeamModel, AppointmentStatusModel, ItemModel, IItemModel, JellyFishOrderingSettingsModel, IJobCostingSetupModel } from "@models";
import { IUserResourceSortOrderModel } from "@models/users/user-resource-sort-order.model";
import { IPreloadCheckSum } from "@models/lookups/preload-check-sum.model";
import { IUserLookupModel } from "@models/lookups/user-lookup.model";
import { GlobalsService } from "./globals.service";
import { DevLogsStore } from "@stores";

enum LookupTypes {
    AppointmentStatuses, AppointmentTypes, ChecklistQuestions, CustomCustomerFields, CustomerAreas, CustomerTypes, Discounts, EmailerAddresses, FuelSurcharges, InvoiceStatuses, InvoiceLaborItems, ItemGroupings, JellyFishOrderingSettings, JobTypes, JobCostingModel, Locations, LathamCarriers, LathamDistributors, MaintenanceReadingItems, PaymentTerms, PriceLevels, QuickAddCategories, Rebates, Resources, ServiceAreas, SmartlistQuestions, TaxRegions, Teams, Users, JobStatuses, NewJobName, UserResourceSortOrder
};

@Injectable()
export class LookupService {
    private static lookupTypes: any = {};
    lookupTypesNames = [
        "AppointmentStatuses", "AppointmentTypes", "ChecklistQuestions", "CustomCustomerFields",
        "CustomerAreas", "CustomerTypes", "Discounts",
        "FuelSurcharges", "InvoiceLaborItems", "InvoiceStatuses", "ItemGroupings", "JellyFishOrderingSettings",
        "JobTypes", "JobStatuses", "JobCostingSetupModel", "Locations", "LathamCarriers",
        "LathamDistributors", "MaintenanceReadingItems", "PaymentTerms",
        "PriceLevels", "QuickAddCategories", "Rebates", "Resources",
        "ServiceAreas", "SmartlistQuestions", "TaxRegions", "Teams",
        "Users", "UserResourceSortOrder"
    ];
    constructor(private httpService: HttpService,
        private devLogsStore: DevLogsStore) {
    }

    async preloadLookups(): Promise<void> {
        let preloadCheckSum: IPreloadCheckSum;
        if (GlobalsService.isOnline) {
            preloadCheckSum = await this.httpService.get("/lookups/getPreloadCheckSum");
            localStorage.setItem("preloadCheckSum", JSON.stringify(preloadCheckSum));
        }
        else {
            preloadCheckSum = JSON.parse(localStorage.getItem("preloadCheckSum"));
        }



        let promisesMap = new Map();

        this.lookupTypesNames.forEach(type => {
            let hashKey = `LOOKUPS_${type.toUpperCase()}_HASH`;
            let hashValue = localStorage.getItem(hashKey);
            if (GlobalsService.isOnline && (hashValue != preloadCheckSum[`${type.charAt(0).toLowerCase() + type.slice(1)}Hash`] || preloadCheckSum[`${type.charAt(0).toLowerCase() + type.slice(1)}Hash`] == null)) {
                const endpoint = `/lookups/get${type}`;
                const promise = this.httpService.get(endpoint);
                promisesMap.set(type, promise);
                localStorage.setItem(hashKey, preloadCheckSum[`${type.charAt(0).toLowerCase() + type.slice(1)}Hash`]);
            }
            else {
                const data = JSON.parse(localStorage.getItem(`LOOKUP_DATA_${type.toUpperCase()}`));
                LookupService.lookupTypes[LookupTypes[type]] = data;
            }
        });
        const keys = Array.from(promisesMap.keys());
        const promises = Array.from(promisesMap.values());
        if (keys.length > 0) {
            try {
                const results = await Promise.all(promises);
                results.forEach((result, index) => {
                    const key = keys[index];
                    console.info(`Storing data for key: ${key}`, result);
                    localStorage.setItem(`LOOKUP_DATA_${key.toUpperCase()}`, JSON.stringify(result));
                    LookupService.lookupTypes[LookupTypes[key]] = result;
                });
            }
            catch (error) {
                console.error("Error with Promise.all:", error);
            }
        }
    }

    public clearLookups() {
        this.lookupTypesNames.forEach(type => {
            const hashKey = `LOOKUPS_${type.toUpperCase()}_HASH`;
            const dataKey = `LOOKUP_DATA_${type.toUpperCase()}`;

            // Remove the hash key and data key from localStorage
            localStorage.removeItem(hashKey);
            localStorage.removeItem(dataKey);

        });
    }


    private async refreshLookup<T>(lookupType: LookupTypes): Promise<T> {
        try {
            const url = "/lookups/get" + LookupTypes[lookupType];

            LookupService.lookupTypes[lookupType] = await this.httpService.get(url);
            return Promise.resolve(LookupService.lookupTypes[lookupType]);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    private async updateLookup<T>(lookupType: LookupTypes, model: T): Promise<T> {
        const url = "/lookups/update" + LookupTypes[lookupType];
        return this.httpService.post(url, model);
    }

    private getLookup<T>(lookupType: LookupTypes, idFieldName: string, id?: number, includeInactive?: boolean): T[] {
        let returnModel = <T[]>LookupService.lookupTypes[lookupType];

        if (!returnModel)
            return [];

        id = id || 0;

        if (returnModel.length === 0)
            return returnModel;

        if (!includeInactive && returnModel[0]["active"] !== undefined)
            returnModel = returnModel.filter(c => (!!c["active"] === true));
        if (id && returnModel.findIndex(c => c[idFieldName] === id) < 0) {
            const inactiveModel = (<T[]>LookupService.lookupTypes[lookupType]).find(c => c[idFieldName] === id);
            if (inactiveModel)
                returnModel.unshift(inactiveModel);
        }

        return returnModel;

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Appointment Statuses
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshAppointmentStatuses(): Promise<IAppointmentStatusModel[]> {
        return this.refreshLookup<IAppointmentStatusModel[]>(LookupTypes.AppointmentStatuses);
    }

    public getAppointmentStatuses(includeInactive: boolean = false): IAppointmentStatusModel[] {
        return this.getLookup<IAppointmentStatusModel>(LookupTypes.AppointmentStatuses, "appointmentStatusId", null, includeInactive);
    }

    public getDefaultAppointmentStatus(): IAppointmentStatusModel {
        const defaultApptStatus = this.getLookup<IAppointmentStatusModel>(LookupTypes.AppointmentStatuses, "appointmentStatusId", null, true).find(x => x.isDefault === true);
        if (defaultApptStatus)
            return defaultApptStatus;
        else {
            const scheduledApptStatus = this.getLookup<IAppointmentStatusModel>(LookupTypes.AppointmentStatuses, "appointmentStatusId", null, true).find(x => x.description === 'Scheduled');
            return scheduledApptStatus;
        }
    }

    public getAppointmentStatus(statusId: number): IAppointmentStatusModel {
        return this.getLookup<IAppointmentStatusModel>(LookupTypes.AppointmentStatuses, "appointmentStatusId", statusId, false).find(x => x.appointmentStatusId === statusId && x.active);
    }

    public getAppointmentStatusesForDropdown(appointmentStatusId?: number): IDropdownModel[] {
        return this.getLookup<IAppointmentStatusModel>(LookupTypes.AppointmentStatuses, "appointmentStatusId", appointmentStatusId)
            .filter(x => x.active == true)
            .map(x => new DropdownModel(x.appointmentStatusId, x.description));
    }

    public async updateAppointmentStatus(appointmentStatusModel: IAppointmentStatusModel): Promise<IAppointmentStatusModel> {
        const returnModel = await this.updateLookup(LookupTypes.AppointmentStatuses, appointmentStatusModel);
        await this.refreshAppointmentStatuses();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Appointment Types
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshAppointmentTypes(): Promise<IAppointmentTypeModel[]> {
        return this.refreshLookup<IAppointmentTypeModel[]>(LookupTypes.AppointmentTypes);
    }

    public getAppointmentTypes(appointmentTypeId?: number, includeInactive?: boolean): IAppointmentTypeModel[] {
        return this.getLookup<IAppointmentTypeModel>(LookupTypes.AppointmentTypes, "appointmentTypeId", appointmentTypeId, includeInactive);
    }

    public getAppointmentTypesForDropdown(appointmentTypeId?: number): IDropdownModel[] {
        return this.getLookup<IAppointmentTypeModel>(LookupTypes.AppointmentTypes, "appointmentTypeId", appointmentTypeId).map(x => new DropdownModel(x.appointmentTypeId, x.description));
    }

    public async updateAppointmentType(appointmentTypeModel: IAppointmentTypeModel): Promise<IAppointmentTypeModel> {
        const returnModel = await this.httpService.post("/lookups/updateAppointmentType", appointmentTypeModel);

        await this.refreshAppointmentTypes();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Checklist Questions
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshChecklistQuestion(): Promise<IChecklistQuestionModel[]> {
        return this.refreshLookup<IChecklistQuestionModel[]>(LookupTypes.ChecklistQuestions);
    }

    public getChecklistQuestions(checklistQuestionId?: number, includeInactive?: boolean): IChecklistQuestionModel[] {
        return this.getLookup<IChecklistQuestionModel>(LookupTypes.ChecklistQuestions, "checklistQuestionId", checklistQuestionId, includeInactive);
    }

    public getChecklistQuestionsForDropdown(checklistQuestionId?: number): IDropdownModel[] {
        return this.getLookup<IChecklistQuestionModel>(LookupTypes.ChecklistQuestions, "checklistQuestionId", checklistQuestionId).map(x => new DropdownModel(x.checklistQuestionId, x.sortOrder));
    }

    public async updateChecklistQuestion(checklistQuestionModel: IChecklistQuestionModel): Promise<IChecklistQuestionModel> {
        const returnModel = await this.updateLookup(LookupTypes.ChecklistQuestions, checklistQuestionModel);
        await this.refreshChecklistQuestion();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Custom Customer Fields
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshCustomCustomerFields(): Promise<ICustomCustomerFieldModel[]> {
        return this.refreshLookup<ICustomCustomerFieldModel[]>(LookupTypes.CustomCustomerFields);
    }

    public getCustomCustomerFields(customCustomerFieldId?: number, includeInactive?: boolean): ICustomCustomerFieldModel[] {
        return this.getLookup<ICustomCustomerFieldModel>(LookupTypes.CustomCustomerFields, "customCustomerFieldId", customCustomerFieldId, includeInactive);
    }

    public async updateCustomCustomerFields(customCustomerFieldModel: ICustomCustomerFieldModel): Promise<ICustomCustomerFieldModel> {
        const returnModel = await this.updateLookup(LookupTypes.CustomCustomerFields, customCustomerFieldModel);
        await this.refreshCustomCustomerFields();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Customer Areas
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshCustomerAreas(): Promise<ICustomerAreaModel[]> {
        return this.refreshLookup<ICustomerAreaModel[]>(LookupTypes.CustomerAreas);
    }

    public getCustomerAreas(customerAreaId?: number, includeInactive?: boolean): ICustomerAreaModel[] {
        return this.getLookup<ICustomerAreaModel>(LookupTypes.CustomerAreas, "customerAreaId", customerAreaId, includeInactive);
    }

    public getCustomerAreasForDropdown(customerAreaId?: number): IDropdownModel[] {
        return this.getLookup<ICustomerAreaModel>(LookupTypes.CustomerAreas, "customerAreaId", customerAreaId).map(x => new DropdownModel(x.customerAreaId, x.name));
    }

    public async updateCustomerArea(customerAreaModel: ICustomerAreaModel): Promise<ICustomerAreaModel> {
        const returnModel = await this.updateLookup(LookupTypes.CustomerAreas, customerAreaModel);
        await this.refreshCustomerAreas();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Customer Types
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshCustomerTypes(): Promise<ICustomerTypeModel[]> {
        return this.refreshLookup<ICustomerTypeModel[]>(LookupTypes.CustomerTypes);
    }

    public getCustomerTypes(customerTypeId?: number, includeInactive?: boolean): ICustomerTypeModel[] {
        return this.getLookup<ICustomerTypeModel>(LookupTypes.CustomerTypes, "customerTypeId", customerTypeId, includeInactive);
    }

    public getCustomerTypesForDropdown(customerTypeId?: number): IDropdownModel[] {
        return this.getLookup<ICustomerTypeModel>(LookupTypes.CustomerTypes, "customerTypeId", customerTypeId).map(x => new DropdownModel(x.customerTypeId, x.name));
    }

    public async updateCustomerType(customerTypeModel: ICustomerTypeModel): Promise<ICustomerTypeModel> {
        const returnModel = await this.updateLookup(LookupTypes.CustomerTypes, customerTypeModel);
        await this.refreshCustomerTypes();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Discounts
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshDiscounts(): Promise<IDiscountModel[]> {
        return this.refreshLookup<IDiscountModel[]>(LookupTypes.Discounts);
    }

    public getDiscounts(discountId?: number, includeInactive?: boolean): IDiscountModel[] {
        return this.getLookup<IDiscountModel>(LookupTypes.Discounts, "discountId", discountId, includeInactive);
    }

    public getDiscountsForDropdown(discountId?: number): IDropdownModel[] {
        return this.getLookup<IDiscountModel>(LookupTypes.Discounts, "discountId", discountId).map(x => new DropdownModel(x.discountId, x.name));
    }

    public async updateDiscounts(discountModels: IDiscountModel[]): Promise<IDiscountModel[]> {
        LookupService.lookupTypes[LookupTypes.Discounts] = await this.httpService.post("/lookups/updateDiscounts", discountModels);
        return this.getDiscounts();
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Email Address Strings
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshEmailerAddresses(): Promise<IEmailerAddressModel[]> {
        return this.refreshLookup<IEmailerAddressModel[]>(LookupTypes.EmailerAddresses);
    }

    public getEmailerAddresses(): IEmailerAddressModel[] {
        return LookupService.lookupTypes[LookupTypes.EmailerAddresses];
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Fuel Surcharges
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshFuelSurcharges(): Promise<IFuelSurchargeModel[]> {
        return this.refreshLookup<IFuelSurchargeModel[]>(LookupTypes.FuelSurcharges);
    }

    public getFuelSurcharges(): IFuelSurchargeModel[] {
        return this.getLookup<IFuelSurchargeModel>(LookupTypes.FuelSurcharges, "fuelSurchargesId");
    }

    public async updateFuelSurcharges(fuelSurchargeModels: IFuelSurchargeModel[]): Promise<IFuelSurchargeModel[]> {
        const returnModel = <IFuelSurchargeModel[]>(await this.httpService.post("/lookups/updateFuelSurcharges", fuelSurchargeModels));
        await this.refreshFuelSurcharges();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Invoice Labor Item
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshInvoiceLaborItems(): Promise<IInvoiceLaborItemModel[]> {
        return this.refreshLookup<IInvoiceLaborItemModel[]>(LookupTypes.InvoiceLaborItems);
    }

    public getInvoiceLaborItems(invoiceLaborItemId?: number, includeInactive?: boolean): IInvoiceLaborItemModel[] {
        return this.getLookup<IInvoiceLaborItemModel>(LookupTypes.InvoiceLaborItems, "invoiceLaborItemId", invoiceLaborItemId, includeInactive);
    }

    public getInvoiceLaborItemsForDropdown(invoiceLaborItemId?: number): IDropdownModel[] {
        return this.getLookup<IInvoiceLaborItemModel>(LookupTypes.InvoiceLaborItems, "invoiceLaborItemId", invoiceLaborItemId).map(x => new DropdownModel(x.invoiceLaborItemId, x.sku));
    }

    public async updateInvoiceLaborItems(invoiceLaborItemModels: IInvoiceLaborItemModel[]): Promise<IInvoiceLaborItemModel[]> {
        LookupService.lookupTypes[LookupTypes.InvoiceLaborItems] = await this.httpService.post("/lookups/updateInvoiceLaborItems", invoiceLaborItemModels);
        return this.getInvoiceLaborItems();
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Invoice Statuses
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshInvoiceStatuses(): Promise<IInvoiceStatusModel[]> {
        return this.refreshLookup<IInvoiceStatusModel[]>(LookupTypes.InvoiceStatuses);
    }

    public getInvoiceStatuses(invoiceStatusId?: number, includeInactive?: boolean): IInvoiceStatusModel[] {
        return this.getLookup<IInvoiceStatusModel>(LookupTypes.InvoiceStatuses, "invoiceStatusId", invoiceStatusId, includeInactive);
    }

    public getInvoiceStatusesForDropdown(invoiceStatusId?: number): IDropdownModel[] {
        return this.getLookup<IInvoiceStatusModel>(LookupTypes.InvoiceStatuses, "invoiceStatusId", invoiceStatusId).map(x => new DropdownModel(x.invoiceStatusId, x.name));
    }

    public async updateInvoiceStatus(invoiceStatusModel: IInvoiceStatusModel): Promise<IInvoiceStatusModel> {
        const returnModel = await this.updateLookup(LookupTypes.InvoiceStatuses, invoiceStatusModel);
        await this.refreshInvoiceStatuses();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // ItemGroupings
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshItemGroupings(): Promise<IItemGroupingModel[]> {
        return this.refreshLookup<IItemGroupingModel[]>(LookupTypes.ItemGroupings);
    }

    public getItemGroupings(itemGroupingId?: number, includeInactive?: boolean): IItemGroupingModel[] {
        return this.getLookup<IItemGroupingModel>(LookupTypes.ItemGroupings, "itemGroupingId", itemGroupingId, includeInactive);
    }

    public getItemGroupingsForDropdown(itemGroupingId?: number): IDropdownModel[] {
        return this.getLookup<IItemGroupingModel>(LookupTypes.ItemGroupings, "itemGroupingId", itemGroupingId).map(x => new DropdownModel(x.itemGroupingId, x.description));
    }

    public async updateItemGroupings(itemGroupingModel: IItemGroupingModel): Promise<IItemGroupingModel> {
        const returnModel = await this.updateLookup(LookupTypes.ItemGroupings, itemGroupingModel);
        await this.refreshItemGroupings();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Job Types
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshJobTypes(): Promise<IJobTypeModel[]> {
        return this.refreshLookup<IJobTypeModel[]>(LookupTypes.JobTypes);
    }

    public getJobTypes(jobTypeId?: number, includeInactive?: boolean): IJobTypeModel[] {
        return this.getLookup<IJobTypeModel>(LookupTypes.JobTypes, "jobTypeId", jobTypeId, includeInactive);
    }

    public getJobTypesForDropdown(jobTypeId?: number): IDropdownModel[] {
        return this.getLookup<IJobTypeModel>(LookupTypes.JobTypes, "jobTypeId", jobTypeId).map(x => new DropdownModel(x.jobTypeId, x.description));
    }

    public async updateJobType(jobTypeModel: IJobTypeModel): Promise<IJobTypeModel> {
        const returnModel = await this.updateLookup(LookupTypes.JobTypes, jobTypeModel);
        await this.refreshJobTypes();
        return returnModel;
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////
    // JellyFish 
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public getJellyFishOrderingSettings(): JellyFishOrderingSettingsModel {
        return LookupService.lookupTypes[LookupTypes.JellyFishOrderingSettings];
    }

    public updateJellyFishOrderingSettings(settings: JellyFishOrderingSettingsModel) {
        LookupService.lookupTypes[LookupTypes.JellyFishOrderingSettings] = settings;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Job Statuses
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshJobStatuses(): Promise<IJobStatusModel[]> {
        return this.refreshLookup<IJobStatusModel[]>(LookupTypes.JobStatuses);
    }

    public getJobStatuses(jobStatusId?: number, includeInactive?: boolean): IJobStatusModel[] {
        return this.getLookup<IJobStatusModel>(LookupTypes.JobStatuses, "jobStatusId", jobStatusId, includeInactive);
    }

    public getJobStatusesForDropdown(jobTypeId?: number): IDropdownModel[] {
        return this.getLookup<IJobStatusModel>(LookupTypes.JobStatuses, "jobStatusId", jobTypeId).map(x => new DropdownModel(x.jobStatusId, x.description));
    }

    public async updateJobStatus(jobStatusModel: IJobStatusModel): Promise<IJobStatusModel> {
        const returnModel = await this.updateLookup(LookupTypes.JobStatuses, jobStatusModel);
        await this.refreshJobStatuses();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Job Costing
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public getJobCostingSetupModel(): IJobCostingSetupModel {
        return LookupService.lookupTypes[LookupTypes["JobCostingSetupModel"]];
    }

    public updateJobCostingSetupModel(jobCostingSetupModel: IJobCostingSetupModel) {
        LookupService.lookupTypes[LookupTypes["JobCostingSetupModel"]] = jobCostingSetupModel;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Locations
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshLocations(): Promise<ILocationModel[]> {
        return this.refreshLookup<ILocationModel[]>(LookupTypes.Locations);
    }

    public getLocations(locationId?: number, includeInactive?: boolean): ILocationModel[] {
        return this.getLookup<ILocationModel>(LookupTypes.Locations, "locationId", locationId, includeInactive);
    }

    public getLocationsForDropdown(locationId?: number): IDropdownModel[] {
        return this.getLookup<ILocationModel>(LookupTypes.Locations, "locationId", locationId).map(x => new DropdownModel(x.locationId, x.name));
    }

    public async updateLocation(locationModel: ILocationModel): Promise<ILocationModel> {
        const returnModel = await this.updateLookup(LookupTypes.Locations, locationModel);
        await this.refreshLocations();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Latham Carriers
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public getLathamCarriers(lathamCarrierId?: number, includeInactive?: boolean): ILathamCarrierModel[] {
        return this.getLookup<ILathamCarrierModel>(LookupTypes.LathamCarriers, "lathamCarrierId", lathamCarrierId, includeInactive);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Latham Distributors
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public getLathamDistributors(): ILathamDistributorModel[] {
        return <ILathamDistributorModel[]>LookupService.lookupTypes[LookupTypes.LathamDistributors];
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Maintenance Reading Items
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public getMaintenanceReadingItems(): IMaintenanceReadingItemModel[] {
        return <IMaintenanceReadingItemModel[]>LookupService.lookupTypes[LookupTypes.MaintenanceReadingItems];
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // New Job Name
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public async getNewJobName(): Promise<string> {
        return this.httpService.get('/companies/getNewJobName');
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Payment Terms
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshPaymentTerms(): Promise<IPaymentTermModel[]> {
        return this.refreshLookup<IPaymentTermModel[]>(LookupTypes.PaymentTerms);
    }

    public getPaymentTerms(paymentTermId?: number, includeInactive?: boolean): IPaymentTermModel[] {
        return this.getLookup<IPaymentTermModel>(LookupTypes.PaymentTerms, "paymentTermId", paymentTermId, includeInactive);
    }

    public getPaymentTermsForDropdown(paymentTermId?: number): IDropdownModel[] {
        return this.getLookup<IPaymentTermModel>(LookupTypes.PaymentTerms, "paymentTermId", paymentTermId).map(x => new DropdownModel(x.paymentTermId, x.name));
    }

    public async updatePaymentTerm(paymentTermModel: IPaymentTermModel): Promise<IPaymentTermModel> {
        const returnModel = await this.updateLookup(LookupTypes.PaymentTerms, paymentTermModel);
        await this.refreshPaymentTerms();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Price Levels
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshPriceLevels(): Promise<IPriceLevelModel[]> {
        return this.refreshLookup<IPriceLevelModel[]>(LookupTypes.PriceLevels);
    }

    public getPriceLevels(priceLevelId?: number, includeInactive?: boolean): IPriceLevelModel[] {
        return this.getLookup<IPriceLevelModel>(LookupTypes.PriceLevels, "priceLevelId", priceLevelId, includeInactive);
    }

    public getPriceLevelsForDropdown(priceLevelId?: number): IDropdownModel[] {
        return this.getLookup<IPriceLevelModel>(LookupTypes.PriceLevels, "priceLevelId", priceLevelId, false).map(x => new DropdownModel(x.priceLevelId, x.name));
    }

    public async updatePriceLevel(priceLevelModel: IPriceLevelModel): Promise<IPriceLevelModel> {
        const returnModel = await this.updateLookup(LookupTypes.PriceLevels, priceLevelModel);
        await this.refreshPriceLevels();
        return returnModel;
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Quick Add Categories
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshQuickAddCategories(): Promise<IQuickAddCategoryModel[]> {
        return this.refreshLookup<IQuickAddCategoryModel[]>(LookupTypes.QuickAddCategories);
    }

    public getQuickAddCategories(quickAddCategoryId?: number, includeInactive?: boolean): IQuickAddCategoryModel[] {
        return this.getLookup<IQuickAddCategoryModel>(LookupTypes.QuickAddCategories, "quickAddCategoryId", quickAddCategoryId, includeInactive);
    }

    public getQuickAddCategoriesForRole(quickAddCategoryId?: number, includeInactive?: boolean): IQuickAddCategoryModel[] {
        let quickAdds = this.getQuickAddCategories(quickAddCategoryId, includeInactive);
        if (GlobalsService.userInfo.roleTypeId <= 3) {
            quickAdds = quickAdds.filter(x => x.showOnAdmin);
        }
        else {
            quickAdds = quickAdds.filter(x => x.showOnServiceTech);
        }
        return quickAdds;
    }

    public getQuickAddCategoriesForDropdown(quickAddCategoryId?: number): IDropdownModel[] {
        return this.getLookup<IQuickAddCategoryModel>(LookupTypes.QuickAddCategories, "quickAddCategoryId", quickAddCategoryId).map(x => new DropdownModel(x.quickAddCategoryId, x.categoryName));
    }

    public async updateQuickAddCategories(quickAddCategoryModel: IQuickAddCategoryModel): Promise<IQuickAddCategoryModel> {
        const returnModel = await this.updateLookup(LookupTypes.QuickAddCategories, quickAddCategoryModel);
        await this.refreshQuickAddCategories();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Rebates
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshRebates(): Promise<IRebateModel[]> {
        return this.refreshLookup<IRebateModel[]>(LookupTypes.Rebates);
    }

    public getRebates(rebateId?: number, includeInactive?: boolean): IRebateModel[] {
        return this.getLookup<IRebateModel>(LookupTypes.Rebates, "rebateId", rebateId, includeInactive);
    }

    public getRebatesForDropdown(rebateId?: number): IDropdownModel[] {
        return this.getLookup<IRebateModel>(LookupTypes.Rebates, "rebateId", rebateId).map(x => new DropdownModel(x.rebateId, x.name));
    }

    public async updateRebates(rebateModels: IRebateModel[]): Promise<IRebateModel[]> {
        LookupService.lookupTypes[LookupTypes.Rebates] = await this.httpService.post("/lookups/updateRebates", rebateModels);
        return this.getRebates();
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Resources
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshResources(): Promise<IResourceModel[]> {
        return this.refreshLookup<IResourceModel[]>(LookupTypes.Resources);
    }

    public getResources(resourceId?: number, includeInactive?: boolean): IResourceModel[] {
        return this.getLookup<IResourceModel>(LookupTypes.Resources, "resourceId", resourceId, includeInactive);
    }

    public getResourcesForDropdown(resourceId?: number): IDropdownModel[] {
        return this.getLookup<IResourceModel>(LookupTypes.Resources, "resourceId", resourceId).map(x => new DropdownModel(x.resourceId, x.resourceName));
    }

    public getAvailableResources(resourceId: number) {
        if (GlobalsService.company.locations !== null && GlobalsService.company.locations.length > 1) {
            return this.getResources(resourceId, false)
                .filter(r => (GlobalsService.userInfo.allowedLocationIds.findIndex(l => l === r.locationId) >= 0));
        }
        else {
            return this.getResources(resourceId, false);
        }
    }

    public getAvailableResourcesForDropdown(resourceId: number) {
        return this.getAvailableResources(resourceId).map(r => new DropdownModel(r.resourceId, r.resourceName));
    }

    public async updateResources(resourceModel: IResourceModel): Promise<IResourceModel> {
        const returnModel = await this.updateLookup(LookupTypes.Resources, resourceModel);
        await this.refreshResources();
        return returnModel;
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Service Areas
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshServiceAreas(): Promise<IServiceAreaModel[]> {
        return this.refreshLookup<IServiceAreaModel[]>(LookupTypes.ServiceAreas);
    }

    public getServiceAreas(serviceAreaId?: number, includeInactive?: boolean): IServiceAreaModel[] {
        return this.getLookup<IServiceAreaModel>(LookupTypes.ServiceAreas, "serviceAreaId", serviceAreaId, includeInactive);
    }

    public getServiceAreasForDropdown(serviceAreaId?: number): IDropdownModel[] {
        return this.getLookup<IServiceAreaModel>(LookupTypes.ServiceAreas, "serviceAreaId", serviceAreaId).map(x => new DropdownModel(x.serviceAreaId, x.name));
    }

    public async updateServiceArea(serviceAreaModel: IServiceAreaModel): Promise<IServiceAreaModel> {
        let returnModel = await this.updateLookup(LookupTypes.ServiceAreas, serviceAreaModel);
        await this.refreshServiceAreas();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Smartlist Questions
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshSmartlistQuestions(): Promise<ISmartlistQuestionModel[]> {
        return this.refreshLookup<ISmartlistQuestionModel[]>(LookupTypes.SmartlistQuestions);
    }

    public getSmartlistQuestions(SmartlistQuestionId?: number, includeInactive?: boolean): ISmartlistQuestionModel[] {
        return this.getLookup<ISmartlistQuestionModel>(LookupTypes.SmartlistQuestions, "SmartlistQuestionId", SmartlistQuestionId, includeInactive);
    }

    public getSmartlistQuestionsForDropdown(SmartlistQuestionId?: number): IDropdownModel[] {
        return this.getLookup<ISmartlistQuestionModel>(LookupTypes.SmartlistQuestions, "SmartlistQuestionId", SmartlistQuestionId).map(x => new DropdownModel(x.smartlistQuestionId, x.question));
    }

    public async updateSmartlistQuestion(SmartlistQuestionModel: ISmartlistQuestionModel): Promise<ISmartlistQuestionModel> {
        const returnModel = await this.updateLookup(LookupTypes.SmartlistQuestions, SmartlistQuestionModel);
        await this.refreshSmartlistQuestions();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Tax Regions
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshTaxRegions(): Promise<ITaxRegionModel[]> {
        return this.refreshLookup<ITaxRegionModel[]>(LookupTypes.TaxRegions);
    }

    public getTaxRegions(TaxRegionId?: number, includeInactive?: boolean): ITaxRegionModel[] {
        return this.getLookup<ITaxRegionModel>(LookupTypes.TaxRegions, "TaxRegionId", TaxRegionId, includeInactive);
    }

    public getTaxRegionsForDropdown(TaxRegionId?: number): IDropdownModel[] {
        return this.getLookup<ITaxRegionModel>(LookupTypes.TaxRegions, "TaxRegionId", TaxRegionId).map(x => new DropdownModel(x.taxRegionId, x.regionName));
    }

    public async updateTaxRegions(taxRegionModels: ITaxRegionModel[]): Promise<ITaxRegionModel[]> {
        LookupService.lookupTypes[LookupTypes.TaxRegions] = await this.httpService.post("/lookups/updateTaxRegions", taxRegionModels);
        return this.getTaxRegions();
    }

    public async findCustomersWithTaxRegion(taxRegionId: number) {
        return await this.httpService.get(`/lookups/FindCustomersWithTaxRegion?taxRegionId=${taxRegionId}`);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Users
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshUsers(): Promise<IUserModel[]> {
        return this.refreshLookup<IUserModel[]>(LookupTypes.Users);
    }

    public getUsers(userId?: number, includeInactive?: boolean): IUserLookupModel[] {
        return this.getLookup<IUserLookupModel>(LookupTypes.Users, "userId", userId, includeInactive);
    }

    public getUsersForDropdown(userId?: number): IDropdownModel[] {
        return this.getLookup<IUserLookupModel>(LookupTypes.Users, "userId", userId).map(x => new DropdownModel(x.userId, x.fullName));
    }

    public async updateUser(UserModel: IUserModel): Promise<IUserModel> {
        const returnModel = await this.updateLookup(LookupTypes.Users, UserModel);
        await this.refreshUsers();
        return returnModel;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Users Resource Sort Order
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public refreshUserResourceSortOrder(): Promise<IUserModel[]> {
        return this.refreshLookup<IUserModel[]>(LookupTypes.UserResourceSortOrder);
    }

    public getUserResourceSortOrder(userId?: number): IUserResourceSortOrderModel[] {
        return this.getLookup<IUserResourceSortOrderModel>(LookupTypes.UserResourceSortOrder, "userId", userId);
    }

    public async updateUserResourceSortOrder(userId: number, model: IUserResourceSortOrderModel[]) {
        LookupService.lookupTypes[LookupTypes.UserResourceSortOrder] = await this.httpService.post("/lookups/updateUserResourceSortOrder", model);
        return this.getUserResourceSortOrder(userId);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Teams
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public getTeamsForDropDown(): IDropdownModel[] {
        return this.getLookup<ITeamModel>(LookupTypes.Teams, "teamId").map(x => new DropdownModel(x.teamId, x.teamName));
    }

    public getTeams(): ITeamModel[] {
        return this.getLookup<ITeamModel>(LookupTypes.Teams, "teamId");
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Time Zones
    ////////////////////////////////////////////////////////////////////////////////////////////////

    public getTimeZonesForDropDown(): IDropdownModel[] {

        const timeZones = [
            new DropdownModel('Atlantic Standard Time', 'Atlantic Standard Time'),
            new DropdownModel('Central Standard Time', 'Central Standard Time'),
            new DropdownModel('Eastern Standard Time', 'Eastern Standard Time'),
            new DropdownModel('Hawaiian Standard Time', 'Hawaiian Standard Time'),
            new DropdownModel('Mountain Standard Time', 'Mountain Standard Time'),
            new DropdownModel('Pacific Standard Time', 'Pacific Standard Time')
        ]

        return timeZones;

    }
}




