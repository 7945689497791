import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection, CapacitorSQLitePlugin, capSQLiteUpgradeOptions} from '@capacitor-community/sqlite';
import { DevLogsStore } from '@stores';
import { Injectable } from '@angular/core';
import { IScheduledAppointmentModel } from '../models';

@Injectable()
export class SQLiteService {
	sqliteConnection!: SQLiteConnection;
	isService: boolean = false;
	platform!: string;
	sqlitePlugin!: CapacitorSQLitePlugin;
	native: boolean = false;

	private _sqliteVersionDict: Map<string, number> = new Map();

	constructor(private devLogsStore: DevLogsStore) {
	}

	async initializePlugin(): Promise<boolean> {
		this.platform = Capacitor.getPlatform();
		if (this.platform === 'ios' || this.platform === 'android')
			this.native = true;
		this.sqlitePlugin = CapacitorSQLite;
		this.sqliteConnection = new SQLiteConnection(this.sqlitePlugin);
		this.isService = true;
		return true;
	}

	async initWebStore(): Promise<void> {
		try {
			await this.sqliteConnection.initWebStore();
			this.devLogsStore.addMessage("Init Web Store");
		} catch (err: any) {
			const msg = err.message ? err.message : err;
			this.devLogsStore.addMessage(`initWebStore: ${err}`);
			return Promise.reject(`initWebStore: ${err}`);
		}
	}

	async openDatabase(dbName: string, version: number): Promise<SQLiteDBConnection> {
		let db: SQLiteDBConnection;
		const retCC = (await this.sqliteConnection.checkConnectionsConsistency()).result;
		let isConn = (await this.sqliteConnection.isConnection(dbName, false)).result;
		if (retCC && isConn) {
			db = await this.sqliteConnection.retrieveConnection(dbName, false);
		} else {
			db = await this.sqliteConnection
				.createConnection(dbName, false, "no-encryption", version, false);
		}
		await db.open();
		this.devLogsStore.addMessage(`Sqlite Connection Opened for ${dbName}`);
		return db;
	}

	async deleteDatabase(dbName: string): Promise<void> {
		await this.sqlitePlugin.deleteDatabase({ database: dbName });
		this._sqliteVersionDict.delete(dbName);
	}

	async getAllDatabases(db: SQLiteDBConnection): Promise<string[]> {
		const allDatabases = (await db.query(`SELECT tbl_name FROM sqlite_master WHERE type = 'table'`)).values as string[];

		return allDatabases;
	}
	async retrieveConnection(dbName: string): Promise<SQLiteDBConnection> {
		return await this.sqliteConnection.retrieveConnection(dbName, false);
	}
	async closeConnection(database: string): Promise<void> {
		return await this.sqliteConnection.closeConnection(database, false);
	}
	async addUpgradeStatement(options: capSQLiteUpgradeOptions): Promise<void> {
		await this.sqlitePlugin.addUpgradeStatement(options);
		return;
	}
	async saveToStore(database: string): Promise<void> {
		return await this.sqliteConnection.saveToStore(database);
	}

	setVersion(dbName: string, version: number) {
		this._sqliteVersionDict.set(dbName, version);

	}
	getVersion(dbName: string) {
		if (this._sqliteVersionDict.has(dbName)) {
			return this._sqliteVersionDict.get(dbName);
		} else {
			return -1;
		}
	}

	static encodeJSON(obj: any): string {
		return JSON.stringify(obj).replace(/'/g, '%27');
	}

	static decodeJSON(str: string): string {
		return str.replace('%27', `'`);
	}
}
