<slick-dialog #addPaymentDialogRef width="600" header="Add Payment" (onClose)="onCancel()">
    <div *ngIf="paymentModel" class="px-3">
        <div class="d-flex row form-group mt-3">
            <div class="col-6">
                <label>Payment Type</label>
                <slick-drop-down [items]="paymentTypes"
                                 [(ngModel)]="paymentModel.paymentType"
                                 [validationIndicator]="paymentModel.paymentType"></slick-drop-down>
                <form-error *ngIf="isSubmitted && !paymentModel.paymentType">Payment Type is required</form-error>
            </div>
            <div class="col-6">
                <label>Payment Date</label>
                <input slick-date-picker
                       type="text"
                       class="form-control"
                       [(ngModel)]="paymentModel.paymentDate"
                       [slick-validation-indicator]="paymentModel.paymentDate" />
                <form-error *ngIf="isSubmitted && !paymentModel.paymentDate">Payment Date is required</form-error>
            </div>
        </div>

        <div class="d-flex row form-group">
            <div class="col-6">
                <label>Ref #</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="paymentModel.referenceNumber" />
            </div>
        </div>
        <div class="d-flex row form-group">
            <div class="col-6">
                <label>Amount</label>
                <input type="text"
                       [slick-currency-mask]
                       class="form-control"
                       [(ngModel)]="paymentModel.amount"
                       [slick-validation-indicator]="paymentModel.amount" />
                <form-error *ngIf="isSubmitted && paymentModel.amount === null">Payment Amount is required</form-error>
                <form-error *ngIf="isSubmitted && paymentModel.amount === 0">Payment Amount cannot be zero</form-error>
            </div>
        </div>

        <div class="d-flex row form-group">
            <div class="col-12">
                <label>Notes</label>
                <textarea type="text"
                          class="form-control"
                          [(ngModel)]="paymentModel.notes"
                          rows="4"></textarea>
            </div>
        </div>

        <div *ngIf="isQuickbooksEnabled" class="d-flex row form-group">
            <div class="col-12">
                <input type="checkbox" [(ngModel)]="sendToQuickbooks" />Send To Quickbooks 
            </div>
        </div>
    </div>

    <slick-dialog-footer>
        <button type="button" [slick-button-spinner]="spinnerStatus" style="width: 160px" class="btn btn-outline-primary me-3" (click)="onSave()"><i class="bi bi-check-circle"></i> Save</button>
        <button type="button" style="width: 160px" class="btn btn-outline-danger" (click)="onCancel()"><i class="bi bi-x-circle"></i> Cancel</button>
    </slick-dialog-footer>
</slick-dialog>
