<slick-container *ngIf="salesOrderModel" maxHeight="calc(100vh - 130px)">
	<slick-container-body>
		<slick-tabs [(tabIndex)]="tabIndex" (tabChange)="onTabChanged($event)">
			<slick-tab header="Sales Order" icon="fa-calculator">

				<slick-container maxHeight="calc(100vh - 265px)">
					<slick-container-body >
						<div class="bold-header" style="display: flex; align-items: center; padding-top: 5px !important;">
                            <div style="flex-grow: 1;">Customer Info</div>
                            <div class="gray-line"></div>
                        </div>
                        <div class="d-flex flex-column mt-2" style="min-width: 600px">
                            <!-- Top Column -->
							<div class="d-flex">
								<div class="d-flex flex-fill">
									<!-- Billing Customer -->
									<div class="width-half me-3">
										<label style="padding-top: 3px; padding-bottom: 5px"><b>Billing Customer</b></label>
										<customer-search [customerModel]="salesOrderModel.customer"
														 (customerModelChange)="onCustomerModelChange($event)"
														 [isBillingCustomer]="true"
														 [disableButtons]="!salesOrdersAuthService.canEdit()"></customer-search>
										<form-error *ngIf="!salesOrderModel.customer && isSubmitted === true">Customer is required</form-error>
									</div>
							
									<!-- Shipping Address -->
									<div *ngIf="salesOrderModel" class="width-half" style="font-size:14px;">
										<shipping-address [customer]="salesOrderModel.customer"
														  [shippingAddress]="salesOrderModel.shippingAddress"
														  [lathamOrderId]="salesOrderModel.lathamOrderId"
														  (shippingAddressChange)="onShippingAddressChanged($event)"></shipping-address>
										<form-error *ngIf="!this.salesOrderModel.shippingAddress.address1 && this.isSubmitted">An address is required</form-error>
									</div>
								</div>
							
								<!-- Job Section -->
								<div *ngIf="salesOrderModel.jobId" class="ms-3 mt-4 text-end" style="font-size: 14px; line-height: 16px;">
									<div *ngIf="job?.jobName">Job Name: {{ job?.jobName }}</div>
									<div *ngIf="job?.jobCustomer.displayName">Job Customer: {{ job?.jobCustomer.displayName }}</div>
									<div *ngIf="job?.jobTypeDisplayName" class="d-flex justify-content-end align-items-center">
										<div class="me-1">
											Job Type:
										</div>
										<div style="max-width: 140px;">
											{{ job?.jobTypeDisplayName }}
										</div>
									</div>
									<div *ngIf="salesOrderModel.preAuthMessage">{{salesOrderModel.preAuthMessage}}</div>
								</div>
							</div>

                            <div *ngIf="salesOrderModel.customer?.billingNotes" class="row col-12 mt-2">
                                <div class="d-flex flex-fill" style="max-width: 100%;">
                                    <div class="flex-fill">
                                        <label>Customer Billing Notes</label>
                                        <pre style="line-height: 1rem; font-family: 'Quicksand', sans-serif;white-space: pre-wrap; word-wrap: break-word;overflow-wrap: break-word;" class="border p-2 m-0">{{salesOrderModel.customer?.billingNotes}}</pre>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3">

								<div class="bold-header mb-1" style="display: flex; align-items: center; padding-top: 5px !important;">
                                    <div style="flex-grow: 1;">Sales Order Info</div>
                                    <div class="gray-line"></div>
                                </div>

								<div class="d-flex align-items-center">
									<div class="flex-grow-1 me-3">
										<label>Sales Order #</label>
										<input type="text" class="form-control" [(ngModel)]="salesOrderModel.invoiceNumber" [slick-validation-indicator]="salesOrderModel.invoiceNumber" />
										<form-error *ngIf="!salesOrderModel.invoiceNumber && isSubmitted === true">Sales Order # is required</form-error>
									</div>
									<div class="flex-grow-1 me-3 ms-1">
										<label>PO #</label>
										<input type="text" class="form-control" [maxlength]="isMizu ? 12 : null" [(ngModel)]="salesOrderModel.purchaseOrderNumber" [slick-validation-indicator]="salesOrderModel.purchaseOrderNumber" slickValidationType="warning" />
									</div>
									<div class="flex-shrink-0 text-end" style="width: auto;" *ngIf="salesOrderModel.createdBy && createdByUser">
										<label>Created By: {{createdByUser.fullName}}</label>
										<div *ngIf="salesOrderModel.acceptedDate">Accepted on {{salesOrderModel.acceptedDate | date:'M/d/yyyy'}} by {{salesOrderModel.acceptedSignature}}</div>
										<div *ngIf="!salesOrderModel.acceptedDate">Not accepted</div>
										<div *ngIf="salesOrderModel.preAuthMessage">{{salesOrderModel.preAuthMessage}}</div>
									</div>
								</div>

                            </div>

                            <div class="row mt-2">
                                <div class="col">
                                    <label>Date</label>
                                    <input type="text" class="form-control" slick-date-picker [(ngModel)]="salesOrderModel.quoteDate" [slick-validation-indicator]="salesOrderModel.quoteDate" slickValidationType="warning" />
                                </div>

                                <div *ngIf="useAdvancedTax === false" class="col">
                                    <label>Tax Rate</label>
                                    <input type="number" class="form-control" [(ngModel)]="salesOrderModel.taxRate" [slick-validation-indicator]="salesOrderModel.taxRate !== null" slickValidationType="warning" />
                                </div>
								<div *ngIf="salesReps.length > 0" class="col">
									<label>Sales Rep:</label>
										<slick-drop-down [ngModel]="salesOrderModel.salesRepUserId"
														 [items]="salesReps"
														 allowEmpty="true"
														 placeholder="None"
														 (onSelect)="onSalesRepSelected($event)"></slick-drop-down>
								</div>
							</div>

							<div class="row mt-3">
                                <div class="col">
                                    <label>Payment Terms</label>
                                    <slick-drop-down [(ngModel)]="salesOrderModel.paymentTermId"
                                                     [items]="paymentTerms"
                                                     allowEmpty="false"
                                                     placeholder="Payment Term"
                                                     [validationIndicator]="salesOrderModel.paymentTermId"></slick-drop-down>
                                </div>

                                <div *ngIf="downPaymentType === 1" class="col">
                                    <label>Down Payment %</label>
                                    <input type="number" step="1" class="form-control" style="text-align: right;" [(ngModel)]="salesOrderModel.downPaymentPercent" />
                                </div>

                                <div *ngIf="downPaymentType === 2" class="col">
                                    <label>Down Payment</label>
                                    <input type="text" class="form-control" [slick-currency-mask] [(ngModel)]="salesOrderModel.downPaymentAmount" />
                                </div>

                                <div *ngIf="showDownPaymentPercent" class="col">
                                    <label>Down Pmt (%)&nbsp;<button type="button" style="font-size: 10px; margin-top: -5px !important;" class="btn btn-link p-0 m-0" (click)="swapDownPaymentType()"><i class="far fa-sync"></i></button></label>
                                    <input type="number" step="1" class="form-control" style="text-align: right;" [(ngModel)]="salesOrderModel.downPaymentPercent" />
                                </div>

                                <div *ngIf="showDownPaymentDollar" class="col">
                                    <label>Down Pmt ($)&nbsp;<button type="button" style="font-size: 10px; margin-top: -5px !important;" class="btn btn-link p-0 m-0" (click)="swapDownPaymentType()"><i class="far fa-sync"></i></button></label>
                                    <input type="text" class="form-control" [slick-currency-mask] [(ngModel)]="salesOrderModel.downPaymentAmount" />
                                </div>
                            </div>

                            <div class="row">
                                <div *ngIf="salesOrderModel?.shippingAddress?.state === 'CO'" class="mt-3">
                                    <input type="checkbox" [(ngModel)]="salesOrderModel.coloradoShippingSurcharge" (change)="onColoradoShippingSurchargeChange()" />Colorado shipping surcharge
                                </div>
                            </div>

							<div *ngIf="useCereTax">
								<input type="checkbox" [(ngModel)]="salesOrderModel.taxExempt" />Tax Exempt
							</div>

                            <div class="mt-3">
                                <invoice-custom-fields [(invoice)]="salesOrderModel"></invoice-custom-fields>
                            </div>

							<div class="row mt-3" style="min-width: 66%;">
								<div class="col mb-2">
									<label>Notes</label>
									<textarea class='form-control' [(ngModel)]='salesOrderModel.notes' style='min-height: 100px'></textarea>
								</div>

								<div class="col mb-2">
									<label>Customer Notes</label>
									<textarea class='form-control' [(ngModel)]='salesOrderModel.customerNotes' style='min-height: 100px'></textarea>
								</div>

							</div>

							<div class="row mt-3">
								<div class="col mb-2" *ngIf="useLathamOrdering">
									<label>Latham Order Notes</label>
									<textarea class='form-control bg-white text-dark' style="min-height: 100px; line-height: 1rem;" readonly>{{salesOrderModel.lathamOrderNotes}}</textarea>
								</div>

								<div class="row mb-2" *ngIf="useLathamOrdering && salesOrderModel.lathamOfficeNotes">
									<label>Latham Office Notes</label>
									<textarea class='form-control bg-white text-dark' style="min-height: 100px; line-height: 1rem;" readonly>{{salesOrderModel.lathamOfficeNotes}}</textarea>
								</div>

							</div>
							
							<div class="bold-header mb-1" style="display: flex; align-items: center; padding-top: 5px !important;">
                                <div style="flex-grow: 1;">Line Items</div>
                                <div class="gray-line"></div>
                            </div>
							
                            <div class="mt-1" *ngIf="useJellyFishOrdering">

								<!-- LOGO HEADER -->
								<div class="logo-header" style="display: flex; align-items: center;">
									<div style="flex-grow: 1;">
										<i><img src="../../../content/images/jelly-fish-lighting-logo.png" style="max-width: 150px; max-height: 150px;" /></i>
									</div>
									<div class="gray-line"></div>
								</div>

								<!-- SPECS -->
								<div class="mt-3" *ngIf="salesOrderModel.jellyFishOrderSpecsModel">
									<jelly-fish-order-specs [(orderSpecs)]="salesOrderModel.jellyFishOrderSpecsModel"
															[customerId]="salesOrderModel.customer?.customerId"
															(onLineItemsChanged)="onLineItemsChanged($event)"
															[lineItems]="salesOrderModel.lineItems"
															[invoiceModel]="salesOrderModel"
															[locked]="salesOrderModel.lockJellyFishSpecs"></jelly-fish-order-specs>
								</div>
							</div>

							<!-- QUICK ADDS FOR JELLYFISH -->
							<div *ngIf="useJellyFishOrdering" class="mt-2">
								<quick-adds [(lineItems)]="salesOrderModel.lineItems"
											(lineItemsChange)="onLineItemsChanged($event)"
											[customerId]="salesOrderModel?.customer?.customerId"></quick-adds>
							</div>

							<!-- Line Items -->
							<line-items class="mt-2" [(lineItems)]="salesOrderModel.lineItems"
										[(amountDue)]="salesOrderModel.outstandingBalance"
										[invoiceModel]="salesOrderModel"
										[appliedPayments]="salesOrderModel.appliedPayments"
										[invoiceGroupSeq]="salesOrderModel.invoiceGroupSeq"
										[customer]="salesOrderModel.customer"
										[jobSiteAddress]="salesOrderModel.jobSiteAddress"
										[shippingAddress]="salesOrderModel.shippingAddress"
										[taxRate]="salesOrderModel.taxRate"
										[addColoradoShippingSurcharge]="salesOrderModel.coloradoShippingSurcharge"
										[pricingChanged]="salesOrderModel.pricingChanged"
										(onRefreshPricing)="onRefreshPricing()"
										[showSendToCheckBox]="useJellyFishOrdering"></line-items>

							<div class="mt-4">
								<quick-adds *ngIf="!useJellyFishOrdering" [(lineItems)]="salesOrderModel.lineItems"
											(lineItemsChange)="onLineItemsChanged($event)"
											[customerId]="salesOrderModel?.customer?.customerId"></quick-adds>
							</div>
                        </div>
					</slick-container-body>
				</slick-container>

			</slick-tab>

			<slick-tab *ngIf="salesOrderModel.invoiceId !== 0 && salesOrderModel.jobId != null && canAccessJobCosting" header="Job Costing" icon="bi-graph-up-arrow">
				<slick-container maxHeight="calc(100vh - 250px)">
					<slick-container-body *ngIf="tabKey === 'Job Costing'">
						<div *ngIf="isJobCostSetupValid">
							<div *ngIf="!salesOrderModel.jobCostingDetailModel">
								<button class="btn btn-outline-primary" (click)="generateJobCostingReport()">Generate Job Costing Report</button>
							</div>
							<div *ngIf="salesOrderModel.jobCostingDetailModel">
								<button class="btn btn-outline-primary" (click)="generateJobCostingReport()">Regenerate Job Costing Report</button>
							</div>
						</div>
						<job-costing-details [isValid]="isJobCostSetupValid" [jobCostingDetailModel]="salesOrderModel.jobCostingDetailModel"></job-costing-details>
					</slick-container-body>
				</slick-container>
			</slick-tab>

			<slick-tab *ngIf="useInventory" header="Inventory" icon="fa-box-full">
				<slick-container maxHeight="calc(100vh - 265px)">
					<slick-container-body>
						<div class="mt-3">
							<input type="checkbox" [checked]="!salesOrderModel.useDefaultInventory" (click)="changeDefaultInventory()" /> Custom Inventory
						</div>
						<inventory-line-items #inventoryLineItemsRef
											  [invoiceUuid]="salesOrderModel.uuid"
											  [(lineItems)]="salesOrderModel.inventoryLineItems"
											  [(inventoryWarehouseId)]="salesOrderModel.inventoryWarehouseId"
											  [editable]="!salesOrderModel.useDefaultInventory"
											  [isSubmitted]="isSubmitted"></inventory-line-items>

					</slick-container-body>
				</slick-container>
			</slick-tab>

			<slick-tab header="Payments" icon="fa-credit-card">
				<slick-container maxHeight="calc(100vh - 265px)">
					<slick-container-body>

						<div class="bold-header" style="display: flex; align-items: center; padding-top: 5px !important;">
                            <div style="flex-grow: 1;">Applied</div>
                            <div class="gray-line"></div>
                        </div>
						<div class="ms-2">
							<div class="mb-3" *ngIf="salesOrderModel.appliedPayments.length === 0">No applied payments</div>
							<table *ngIf="salesOrderModel.appliedPayments.length > 0" class="table table-sm striped table-borderless">

								<colgroup>
									<col style="width: 25px" />
									<col style="width: 120px" />
									<col style="width: 120px" />
									<col style="width: 120px" />
									<col style="width: 120px" />
									<col style="width: 120px" />
									<col style="width: 60px" />
								</colgroup>

								<thead>
									<tr class="border-bottom">
										<td></td>
										<td class="text-primary">Reference #</td>
										<td class="text-primary">Payment Date</td>
										<td style="text-align: right;" class="text-primary">Payment Total</td>
										<td style="text-align: right;" class="text-primary">Applied Amount</td>
										<td style="text-align: right;" class="text-primary">Payment Remaining</td>
										<td></td>
									</tr>
								</thead>

								<tbody style="vertical-align: middle;">
									<tr *ngFor="let appliedPayment of salesOrderModel.appliedPayments">
										<td style="text-align: left;  max-width: 10px;">
											<button type="button" class="btn btn-link text-danger" (click)="removeAppliedPayment(appliedPayment)"><i class="far fa-minus-circle"></i></button>
										</td>
										<td>{{appliedPayment.payment?.referenceNumber}}</td>
										<td>{{appliedPayment.payment?.paymentDate | date:'M/d/yyyy'}}</td>
										<td style="text-align: right;">{{appliedPayment.payment?.amount | currency}}</td>
										<td style="text-align: right;">
											<input type="text" class="form-control" style="text-align: right; padding: 2px; padding-right: 5px; height: 26px;" [slick-currency-mask] [(ngModel)]="appliedPayment.appliedAmount" (change)="onChangeAppliedAmount(appliedPayment)" />
										</td>
										<td style="text-align: right;">{{(appliedPayment.payment?.amount - (appliedPayment.appliedAmount || 0)) | currency }}</td>
										<td style="text-align: right;">
											<button type="button" style="font-size: 1rem; line-height: 22px;" class="btn btn-outline-primary btn-sm me-1 py-0 px-1" (click)="printAppliedPayment(appliedPayment)"><i class="far fa-print"></i></button>
											<button type="button" style="font-size: 1rem; line-height: 22px;" class="btn btn-outline-primary btn-sm me-1 py-0 px-1" (click)="emailAppliedPayment(appliedPayment)"><i class="far fa-envelope"></i></button>
                                        </td>
									</tr>
								</tbody>
							</table>

							<table *ngIf="salesOrderModel.appliedPayments.length > 0" class="table table-sm table-borderless">

                                <colgroup>
                                    <col style="width: 100%" />
									<col style="min-width: 200px" />
									<col style="min-width: 40px" />
									<col style="min-width: 50px" />
									<col style="min-width: 110px" />
                                </colgroup>

                                <tbody style="vertical-align: middle;">
                                    <tr>
                                        <td></td>
										<td style="text-align: right;"><b>Sales Order Total:</b></td>
										<td></td>
										<td style="text-align: right;"><b>{{salesOrderModel?.invoiceTotal | currency}}</b></td>
										<td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
										<td style="text-align: right;"><b>Applied Total:  </b></td>
										<td></td>
										<td style="text-align: right;"><b>{{ calculateAppliedTotal() | currency }}</b></td>
										<td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style="font-weight: bold; text-align: right; border-top: 2px solid var(--bs-border-color);">Balance Due:</td>
                                        <td style="border-top: 2px solid var(--bs-border-color);"></td>
                                        <td style="font-weight: bold; text-align: right; border-top: 2px solid var(--bs-border-color);">
                                            <div>{{salesOrderModel.outstandingBalance | currency}}</div>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
						</div>

						<button class="btn btn-outline-primary my-2" (click)="addPayment()"><i class="far fa-plus"></i> Add Payment</button>

						<div class="bold-header" style="display: flex; align-items: center; padding-top: 5px !important;">
							<div *ngIf="!paymentsExpanded" (click)="paymentsExpanded = !paymentsExpanded" style="flex-grow: 1;"> <i class="bi bi-plus-circle"></i></div>
							<div *ngIf="paymentsExpanded" (click)="paymentsExpanded = !paymentsExpanded" style="flex-grow: 1;"> <i class="bi bi-dash-circle"></i></div>
                            <div style="flex-grow: 1;">Payments</div>
                            <div class="gray-line"></div>
                        </div>
						<div class="ms-2" *ngIf="paymentsExpanded">
							<div class="mb-3" *ngIf="!unappliedPayments">Loading...</div>
							<div class="mb-3" *ngIf="unappliedPayments && unappliedPayments.length === 0">No payments have been made</div>
							<table *ngIf="unappliedPayments && unappliedPayments.length > 0" class="table table-sm striped table-borderless">

								<colgroup>
									<col style="width: 25px" />
									<col style="width: 120px" />
									<col style="width: 120px" />
									<col style="width: 120px" />
									<col style="width: 120px" />
									<col style="width: 120px" />
								</colgroup>

								<thead>
									<tr class="border-bottom">
										<td></td>
										<td class="text-primary">Reference #</td>
										<td class="text-primary">Payment Date</td>
										<td style="text-align: right;" class="text-primary">Total Amount</td>
										<td style="text-align: right;" class="text-primary">Applied Amount</td>
										<td style="text-align: right;" class="text-primary">Remaining Balance</td>
									</tr>
								</thead>

								<tbody style="vertical-align: middle;">
									<tr *ngFor="let unappliedPayment of unappliedPayments">
										<td style="text-align: left; max-width: 10px;"><button type="button" class="btn btn-link text-success" (click)="addAppliedPayment(unappliedPayment)"><i class="far fa-plus-circle"></i></button></td>
										<td>{{unappliedPayment.referenceNumber}}</td>
										<td>{{unappliedPayment.paymentDateDisplay}}</td>
										<td style="text-align: right;">{{unappliedPayment.amountDisplay}}</td>
										<td style="text-align: right;">{{unappliedPayment.appliedPaymentAmount | currency}}</td>
										<td style="text-align: right;">{{unappliedPayment.totalRemainingAmountDisplay}}</td>
										
									</tr>
								</tbody>
							</table>
						</div>
					</slick-container-body>
				</slick-container>
			</slick-tab>

			<slick-tab header="Photos" icon="fa-camera">
				<slick-container maxHeight="calc(100vh - 265px)">
					<slick-container-body>
						<documents-edit *ngIf="tabKey === 'Photos'"
										[documentKey]="salesOrderModel.documentKey"
										documentType="Invoices"
										 title="Sales Order Photos"
										folderType="photos"
										[canDelete]="true"
										[hideFileDrop]="!salesOrdersAuthService.canEdit()"></documents-edit>
					</slick-container-body>
				</slick-container>
			</slick-tab>

			<slick-tab *ngIf="salesOrderModel?.jobId" header="Job Photos" icon="fa-camera">
				<slick-container maxHeight="calc(100vh - 265px)">
					<slick-container-body>
						<div *ngIf="!jobPhotos">Loading...</div>
						<div *ngIf="jobPhotos?.length === 0">No Photos</div>
						<div *ngIf="jobPhotos?.length > 0">
							<slick-file-list #jobPhotosRef
											 [files]="jobPhotos"
											 [thumbnailSize]="120"
											 [showPhotoGallery]="true"
											 [showCheckboxes]="true"
											 [allowDelete]="false"
											 (onFileCheckChanged)="onFileCheckChanged($event)"></slick-file-list>
						</div>
					</slick-container-body>
				</slick-container>
			</slick-tab>

			<slick-tab header="Documents" icon="fa-file-alt">
				<slick-container maxHeight="calc(100vh - 265px)">
					<slick-container-body>
						<documents-edit *ngIf="tabKey === 'Documents'"
										[documentKey]="salesOrderModel.documentKey"
										documentType="Invoices"
										 title="Sales Order Documents"
										folderType="documents"
										[canDelete]="true"
										[hideFileDrop]="!salesOrdersAuthService.canEdit()"></documents-edit>
					</slick-container-body>
				</slick-container>
			</slick-tab>

			<slick-tab header="System Logs" icon="fa-info-circle">
				<slick-container maxHeight="calc(100vh - 265px)">
					<slick-container-body>
						<system-logs *ngIf="tabKey === 'System Logs'" noteType="2" [referenceId]="salesOrderModel.invoiceId"></system-logs>
					</slick-container-body>
				</slick-container>
			</slick-tab>
		</slick-tabs>

	</slick-container-body>

	<slick-container-footer *ngIf="!isDialog">
		<div class="d-flex flex-column" *ngIf="salesOrdersAuthService.canEdit()">
			<div class="mt-2 pt-2 mb-3 flex-column">
				<div class="row">
					<div class="col">
						<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-success me-2" style="width: 140px;" (click)="saveSalesOrder()"><i class="bi bi-check-circle"></i> Save</button>
						<button type="button" class="btn btn-outline-danger" style="width: 140px;" (click)="cancelSalesOrder()"><i class="bi bi-x-circle"></i> Cancel</button>
					</div>

					<div class="col text-end me-2">
						<div class="btn-group me-1" role="group">
							<button type="button" [disabled]="salesOrderModel.invoiceId === 0" class="btn btn-outline-primary" title="Convert to Invoice" (click)="convertToInvoice()"><i class="far fa-file-invoice-dollar"></i></button>
							<button type="button" [disabled]="salesOrderModel.invoiceId === 0" class="btn btn-outline-primary" title="Print Sales Order" (click)="generateSalesOrderPdf()"><i class="far fa-print"></i></button>
							<button type="button" [disabled]="salesOrderModel.invoiceId === 0" class="btn btn-outline-primary" title="Print Work Order" (click)="generateWorkOrderPdf()">
								<div style="position: absolute; font-size: 6px; border-radius: 6px; right: 6px; top: 18px; line-height: 12px; min-width: 12px; padding: 0;" class="bg-dark text-white">WO</div>
								<i class="far fa-print"></i>
							</button>
							<button type="button" [disabled]="salesOrderModel.invoiceId === 0" class="btn btn-outline-primary" title="Email Sales Order" (click)="emailSalesOrder()"><i class="far fa-envelope"></i></button>
							<button type="button" [disabled]="salesOrderModel.invoiceId === 0" class="btn btn-outline-primary" title="Text Sales Order" (click)="textSalesOrder()"><i class="far fa-comment-alt-lines"></i></button>

							<button type="button" [disabled]="salesOrderModel.invoiceId === 0" class="btn btn-outline-primary" title="Duplicate to new Sales Order" (click)="duplicateSalesOrder()"><i class="far fa-copy"></i></button>
							<button type="button" [disabled]="salesOrderModel.invoiceId === 0" class="btn btn-outline-primary" title="Pre-Payment" (click)="prePayment()"><i class="far fa-credit-card"></i></button>
							<button *ngIf="useShipping" type="button" [disabled]="salesOrderModel.invoiceId === 0" class="btn btn-outline-primary" title="Create shipping record" (click)="createShippingRecord()"><i class="bi bi-truck-front"></i></button>

						</div>
						<!--<button type="button" *ngIf="useJellyFishOrdering" [disabled]="salesOrderModel.invoiceId === 0 || salesOrderModel.jellyFishOrderId" class="btn btn-outline-primary ms-2 me-2" title="Create JellyFish Order" (click)="createJellyFishOrder()"><i><img src="../../../content/images/jelly-fish-logo-colored.png" style="max-width: 20px; max-height: 20px;" /></i> </button>-->
						<button *ngIf="canMoveToEstimate" type="button" [disabled]="salesOrderModel.invoiceId === 0" class="btn btn-outline-primary" title="Back to Estimate" (click)="convertToEstimate()"><i class="far fa-arrow-alt-left" style="margin-right: 2px;"></i><i class="far fa-calculator"></i></button>
					</div>
				</div>
			</div>
		</div>

		<div class="d-flex flex-fill" *ngIf="!salesOrdersAuthService.canEdit()">
			<permission-denied [message]="'*You do not have permission to edit Sales Orders.'"></permission-denied>
		</div>


	</slick-container-footer>
</slick-container>

<credit-card-dialog #creditCardDialogRef
					(onApproved)="onApproved($event)"
					(onPaymentTypesChanged)="onPaymentTypesChanged($event)"></credit-card-dialog>

<emailer-dialog #emailerDialogRef></emailer-dialog>
<texter-dialog #texterDialogRef></texter-dialog>
<add-payment-dialog #addPaymentDialogRef></add-payment-dialog>
<credit-card-selector-dialog #creditCardSelectorDialogRef></credit-card-selector-dialog>

