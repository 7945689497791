import { Component, Input, OnChanges, Output, EventEmitter, ViewChild, HostBinding, SimpleChanges, OnInit } from '@angular/core';
import { InvoicesAuthService } from '@services/utils/auth-services/invoices-auth.service';
import { SleepService, GlobalsService, LookupService, PaymentsService, UtilsService, InvoicesService, LineItemsService, JobsService, FunctionLockService, ShippingService, LathamOrdersService, JellyFishService, HttpService, JobCostingService } from '@services';
import { ISlickFileModel, SlickDialogComponent, SlickFileListComponent, SlickScreenBlockerService, SlickToastService } from "@slick-components";
import { IInvoiceModel, IDropdownModel, ICustomerModel, CreditCardProcessPaymentModel, ICreditCardPaymentTypeModel, ICreditCardPaymentApprovedModel, IPaymentListViewModel, IAppliedPaymentModel, UserModel, EmailerModel, AppliedPaymentModel, DropdownModel, IDocumentModel, LineItemTypes, ILineItemModel, AddressModel, ShippingAddressModel, IShippingAddressModel, ILathamOrderModel, IJellyFishOrderModel, JellyFishOrderSpecsModel, UserLookupModel, IJobCostingDetailModel, IJobCostingSetupModel, JobModel } from '@models';
import { CreditCardDialogComponent } from '@components/credit-card-dialog/credit-card-dialog.module';
import { CreditCardSelectorDialogComponent } from '@shared-components/credit-card-selector-dialog/credit-card-selector-dialog.module';
import { EmailerDialogComponent } from "@components/emailer-dialog";
import * as moment from "moment";
import { AddPaymentDialogComponent } from "@app/payments/payments-components";
import Swal from 'sweetalert2';
import { InventoryStore } from '@stores';
import { CompareLathamBillDialogComponent } from '@app/latham-orders/compare-latham-bill-dialog';
import { InventoryLineItemsComponent } from '@shared-components/inventory-line-items';
import { TexterDialogComponent } from '@shared-components/texter-dialog/texter-dialog.component';
import { JellyFishSystemLineItemService } from '@services/jelly-fish-system-line-items.service';

@Component({
	selector: 'invoice-edit',
	templateUrl: './invoice-edit.component.html',
	styleUrls: ["./invoice-edit.component.scss"],
	providers: [InvoicesAuthService, JobsService, FunctionLockService, ShippingService, LathamOrdersService, JellyFishService, JellyFishSystemLineItemService, JobCostingService]
})

export class InvoiceEditComponent implements OnChanges, OnInit {
	@HostBinding('style.flex') flex = '1';
	@Input() invoiceModel: IInvoiceModel;
	@Input() isDialog: boolean = false;
	@Input() jellyFishIsApproved: boolean = false;
	@Output() invoiceModelChange: EventEmitter<IInvoiceModel> = new EventEmitter<IInvoiceModel>();
	@Output() onSave = new EventEmitter<IInvoiceModel>();
	@Output() onCancel = new EventEmitter<void>();
	@Output() onDuplicate = new EventEmitter<void>();
	@Output() onJellyFishOrderApproved = new EventEmitter<IJellyFishOrderModel>();

	@ViewChild("jobPhotosRef") jobPhotosRef: SlickFileListComponent;
	@ViewChild("creditCardDialogRef") creditCardDialogRef: CreditCardDialogComponent;
	@ViewChild("creditCardSelectorDialogRef") creditCardSelectorDialogRef: CreditCardSelectorDialogComponent;
	@ViewChild("emailerDialogRef") emailerDialogRef: EmailerDialogComponent;
	@ViewChild("addPaymentDialogRef") addPaymentDialogRef: AddPaymentDialogComponent;
	@ViewChild("qbDocNumberChangeDialogRef") qbDocNumberChangeDialogRef: SlickDialogComponent;
	@ViewChild("compareDialogRef") compareDialogRef: CompareLathamBillDialogComponent;
	@ViewChild("inventoryLineItemsRef") inventoryLineItemsRef: InventoryLineItemsComponent;
	@ViewChild("texterDialogRef") texterDialogRef: TexterDialogComponent;


	isUserAdmin: boolean = (GlobalsService.userInfo.roleTypeId === 1);
	isLathamOrder: boolean;
	adminEdit: boolean = false;
	isMizu = (GlobalsService.company.companyId === 1);
	isCanada = (GlobalsService.company.companyId === 3);
	useAdvancedTax: boolean = GlobalsService.company.useAdvancedTax;
	isQuickbooksEnabled = GlobalsService.isQuickbooksEnabled;
	useBusinessCentral = GlobalsService.company.businessCentralEnabled;
	useLathamOrdering = GlobalsService.company.useLathamOrdering;
	paymentTerms: IDropdownModel[];
	spinnerStatus: string = null;
	qbDocNumberSpinnerStatus: string;
	isSubmitted = false;
	flagShipDate: boolean = false;
	tabIndex = 0;
	tabKey: string;
	unappliedPayments: IPaymentListViewModel[];
	invoiceStatuses: IDropdownModel[];
	createdByUser: UserLookupModel;
	showLathamOrderCheckboxes = (GlobalsService.company.companyId === 1)
	useInventory = GlobalsService.company.useInventory;
	useShipping = GlobalsService.company.useShipping;
	inventoryWarehouses: IDropdownModel[];
	hasInventoryItems: boolean;
	salesReps: IDropdownModel[] = [];
	jobPhotos: IDocumentModel[];
	job: JobModel;
	useCreditCardProcessor: boolean = GlobalsService.company.useCreditCardProcessor;
	useJellyFishOrdering: boolean = GlobalsService.company.parentCompanyId === 133 || GlobalsService.company.companyId === 133;
	paymentsExpanded: boolean = false
	
	jobCostingSetupModel: IJobCostingSetupModel;
	isJobCostSetupValid: boolean = true;


	daysUntilOverdue: number

	adminUnlock: boolean = GlobalsService.checkPermission("Invoices", "adminUnlock");
	canMoveToSalesOrder: boolean = GlobalsService.checkPermission("Invoices", "CanMoveToSalesOrder");

	parentMaxHeight = (GlobalsService.userInfo.layoutSettings.invoicesLayout === 'grid-full-screen') ? "calc(100vh - 140px)" : "calc(100vh - 80px)"
	tabMaxHeight = (GlobalsService.userInfo.layoutSettings.invoicesLayout === 'grid-full-screen') ? "calc(100vh - 264px)" : "calc(100vh - 215px)"
	carriers: IDropdownModel[] = [];
	lockPaymentAmount: boolean;
	canAccessJobCosting: boolean = GlobalsService.checkPermission("JobCosting", "Access");



	constructor(
		private httpService: HttpService,
		public invoicesAuthService: InvoicesAuthService,
		private invoicesService: InvoicesService,
		private lineItemsService: LineItemsService,
		private paymentsService: PaymentsService,
		private jellyFishService: JellyFishService,
		private jobCostingService: JobCostingService,
		private jobsService: JobsService,
		private functionLockService: FunctionLockService,
		private lookupService: LookupService,
		private inventoryStore: InventoryStore,
		private slickToastService: SlickToastService,
		private slickScreenBlockerService: SlickScreenBlockerService,
		private shippingService: ShippingService) {

	}

	async ngOnInit() {
		this.job = null;
		if (this.useShipping)
			this.carriers = await this.httpService.get("/carrier/getcarriersfordropdown");

		this.jobCostingSetupModel = await this.lookupService.getJobCostingSetupModel();
	}

	async ngOnChanges(changes: SimpleChanges) {
		this.isSubmitted = false;
		this.spinnerStatus = "reset";
		this.adminEdit = false;
		this.hasInventoryItems = false;
		this.jobPhotos = null;
		this.isLathamOrder = false;
		this.paymentsExpanded = false
		this.tabIndex = 0;

		if (changes.isDialog)
			this.isDialog = (this.isDialog.toString().toLowerCase() === 'true') ? true : false;

		if (this.isDialog === true) {
			this.parentMaxHeight = null;
			this.tabMaxHeight = null;
		}

		if (this.invoiceModel) {
			this.isLathamOrder = ((this.invoiceModel?.lathamOrderId ?? 0) > 0);
			this.salesReps = this.lookupService.getUsers(this.invoiceModel.salesRepUserId)
				.filter(x => x.isSalesRep === true)
				.map(x => new DropdownModel(x.userId, x.fullName));

			// If this is a new invoice, default to adminEdit
			if (this.invoiceModel.invoiceId === 0) {
				this.invoiceModel.invoiceDate = moment().startOf("date").toDate();
				this.adminEdit = true;
			}

			this.paymentTerms = this.lookupService.getPaymentTermsForDropdown(this.invoiceModel.paymentTermId);
			this.daysUntilOverdue = this.invoiceModel.paymentTerm?.daysUntilOverdue;
			this.invoiceStatuses = this.lookupService.getInvoiceStatusesForDropdown(this.invoiceModel.invoiceStatusId);
			// Need to remove the open/closed status because we can't set an invoice to this
			this.invoiceStatuses = this.invoiceStatuses.filter(x => x.text !== 'All Invoices' && x.text !== 'Open/Closed');
			this.unappliedPayments = null;
			if (this.tabKey === "Payments")
				this.unappliedPayments = await this.paymentsService.getUnappliedPayments(this.invoiceModel.customer.customerId);

			if(this.invoiceModel.jobId){
				this.job = await this.jobsService.getJob(this.invoiceModel.jobId);
			}

			if (this.tabKey === "Inventory" && this.invoiceModel.useDefaultInventory) {
				this.invoiceModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.invoiceModel);
			}

            if (this.tabKey === "Job Costing" && this.invoiceModel.invoiceId !== 0) {
				if (!this.jobCostingService.isValidJobCostingSetupModel(this.jobCostingSetupModel)) {
                    this.isJobCostSetupValid = false;
                    return;
                }

            }

			if (this.invoiceModel.createdBy)
				this.createdByUser = this.lookupService.getUsers().find(x => x.userId === this.invoiceModel.createdBy);

			if (this.useInventory) {
				this.inventoryWarehouses = this.inventoryStore.getInventoryWarehousesForDropdown(this.invoiceModel.inventoryWarehouseId);
				if (!this.invoiceModel.inventoryWarehouseId)
					this.invoiceModel.inventoryWarehouseId = this.inventoryWarehouses[0].id;
			}

			if (this.useJellyFishOrdering) {
				if (!this.invoiceModel.jellyFishOrderSpecsModel)
					this.invoiceModel.jellyFishOrderSpecsModel = new JellyFishOrderSpecsModel();
			}
		}
	}

	calculateAppliedTotal(): number {
		let appliedTotal = 0;
		for (const appliedPayment of this.invoiceModel.appliedPayments) {
			appliedTotal += appliedPayment.appliedAmount || 0;
		}
		return appliedTotal;
	}

	async createShippingRecord() {
		try {

			if (this.invoiceModel.shippingRecordId) {
				await Swal.fire({
					icon: 'warning',
					title: 'Oops',
					text: 'This invoice already has a shipping record linked',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});
				return;
			}

			this.isSubmitted = true;
			await this.saveInvoice();
			this.slickScreenBlockerService.block();

			this.invoiceModel.shippingRecordId = (await this.shippingService.createShippingRecordFromInvoice(this.invoiceModel.invoiceId)).shippingRecordId;
			this.slickScreenBlockerService.unblock();
			this.slickToastService.showSuccess("Shipping record created")
		}
		catch {
			this.slickScreenBlockerService.unblock();

			this.slickToastService.showDanger("Error creating shipping record")
		}
	}

	async onInvoiceDateChange(date: Date) {
		await SleepService.sleep();

		this.invoiceModel.invoiceDueDate = moment(date).add(this.daysUntilOverdue, "days").toDate();
	}

	onPaymentTermSelect(paymentTerm: IDropdownModel) {
		this.daysUntilOverdue = this.lookupService.getPaymentTerms().find(x => x.paymentTermId === paymentTerm.id).daysUntilOverdue;
		this.invoiceModel.invoiceDueDate = moment(this.invoiceModel.invoiceDate).add(this.daysUntilOverdue, "days").toDate();
	}

	async onTabChanged(tabKey: string) {
		this.tabKey = tabKey;

		if (tabKey === 'Payments' && this.invoiceModel.customer && !this.unappliedPayments) {
			this.unappliedPayments = await this.paymentsService.getUnappliedPayments(this.invoiceModel.customer.customerId);
		}

		if (tabKey === "Job Photos" && !this.jobPhotos) {
			this.refreshJobPhotos();
		}

		if (this.tabKey === "Inventory" && this.invoiceModel.useDefaultInventory) {
			this.invoiceModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.invoiceModel);
		}

		if (this.tabKey === "Job Costing") {
			if (!this.jobCostingService.isValidJobCostingSetupModel(this.jobCostingSetupModel)) {
				this.isJobCostSetupValid = false;
				return;
			}
        }
	}

	async generateJobCostingReport() {
		try {
			this.slickScreenBlockerService.forceBlock();

			this.invoiceModel.jobCostingDetailModel = await this.jobCostingService.generateJobCostingDetail(this.invoiceModel.invoiceId, this.invoiceModel.jobId);
			this.slickScreenBlockerService.forceUnblock();
			this.slickToastService.showSuccess("Report updated");
		}
		catch {
			this.slickScreenBlockerService.forceUnblock();
			this.slickToastService.showDanger("Error generating report")
		}

	}

	onSalesRepSelected(salesRep: IDropdownModel) {
		if (!salesRep) {
			this.invoiceModel.salesRepUserId = null;
			this.invoiceModel.salesRepFullName = null;

		}
		else {
			this.invoiceModel.salesRepUserId = salesRep.id;
			this.invoiceModel.salesRepFullName = salesRep.text;
		}
	}

	async onCustomerModelChange(customerModel: ICustomerModel) {
		this.invoiceModel.customer = customerModel;
		if (customerModel) {
			// mapping the jobsite address here since we need it for fuel surcharge
			this.invoiceModel.jobSiteAddress = this.invoicesService.mapAddressFromCustomer(customerModel);

			this.invoiceModel.paymentTermId = customerModel.paymentTermId;
			this.invoiceModel.lineItems = await this.lineItemsService.refreshPricing(this.invoiceModel.lineItems, customerModel);
			this.invoiceModel.pricingChanged = false;
			if (this.invoiceModel.customer.salesRepId) {
				this.invoiceModel.salesRepUserId = this.invoiceModel.customer.salesRepId;
				this.invoiceModel.salesRepFullName = this.lookupService.getUsers(this.invoiceModel.salesRepUserId).find(x => x.userId === this.invoiceModel.salesRepUserId).fullName;
			}
		}
	}

	async onShippingAddressChanged(newShippingAddress: IShippingAddressModel) {
		if (newShippingAddress.address1 != this.invoiceModel.shippingAddress.address1 ||
			newShippingAddress.address2 != this.invoiceModel.shippingAddress.address2 ||
			newShippingAddress.city != this.invoiceModel.shippingAddress.city ||
			newShippingAddress.state != this.invoiceModel.shippingAddress.state ||
			newShippingAddress.zipcode != this.invoiceModel.shippingAddress.zipcode ||
			newShippingAddress.country != this.invoiceModel.shippingAddress.country)
			this.invoiceModel.flagSaveForTaxUpdate = true;

		//if (this.invoiceModel.shippingAddress?.shippingAddressId === newShippingAddress.shippingAddressId)
		//	return;

		this.invoiceModel.shippingAddress = newShippingAddress;
		this.invoiceModel.cereTaxRate = newShippingAddress.taxRate;
		this.invoiceModel.lineItems = this.lineItemsService.recalculateByInvoice(this.invoiceModel);
	}

	async onColoradoShippingSurchargeChange() {
		await SleepService.sleep();

		this.invoiceModel.lineItems = this.lineItemsService.recalculateByInvoice(this.invoiceModel);
	}

	async onAdminEdit() {
		this.adminEdit = true;
		this.invoiceModel.lineItems = this.lineItemsService.recalculateByInvoice(this.invoiceModel);
	}

	async convertToSalesOrder(): Promise<IInvoiceModel> {
		this.invoiceModel.invoiceStatusId = 1;
		this.invoiceModel.isQuote = false
		this.invoiceModel.isSalesOrder = true;

		this.invoiceModel.invoiceDate = new Date();

		await this.saveInvoice();

		return this.invoiceModel;
	}

	async onFileCheckChanged(fileModel: any) {
		await this.functionLockService.lock("ON_FILE_CHECK_CHANGED");
		try {
			await this.invoicesService.updateJobPhotoCheckChanged(this.invoiceModel.invoiceId, fileModel.documentId, fileModel.isChecked);
		}
		finally {
			this.functionLockService.release("ON_FILE_CHECK_CHANGED");
		}
	}

	async changeDefaultInventory() {
		await SleepService.sleep();
		this.invoiceModel.useDefaultInventory = !this.invoiceModel.useDefaultInventory;
		if (this.invoiceModel.useDefaultInventory)
			this.invoiceModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.invoiceModel);
	}

	async generateInvoicePdf() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');

		const invoiceUrl = await this.invoicesService.generateInvoicePdf(this.invoiceModel, false);
		w.location.href = invoiceUrl;
	}

	async generateWorkOrderPdf() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const invoiceUrl = await this.invoicesService.generateWorkOrderPdf(this.invoiceModel);
		w.location.href = invoiceUrl;
	}

	async emailInvoice() {
		this.isSubmitted = true;
		this.spinnerStatus = "spin"
		try {
			if (this.isValid() === false) {
				this.spinnerStatus = "error";
				return;
			}
			await this.saveInvoice();
			this.spinnerStatus = "ok"
		}
		catch {
			this.spinnerStatus = "error"
			return;
		}

		this.slickScreenBlockerService.block();
		try {
			const emailerModel = await this.invoicesService.prepareInvoiceEmail(this.invoiceModel);
			this.slickScreenBlockerService.unblock();

			const sentEmail = await this.emailerDialogRef.showDialog(emailerModel);

			if (sentEmail) {
				await this.invoicesService.addInvoiceEmailSentNote(this.invoiceModel.invoiceId, false);
			}
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}

	async textInvoice() {
		this.slickScreenBlockerService.block();
		try {
			const textModel = await this.invoicesService.prepareInvoiceText(this.invoiceModel);
			this.slickScreenBlockerService.unblock();
			const sent = await this.texterDialogRef.showDialog(textModel);
			if (sent)
				this.slickToastService.showSuccess("Invoice sent");
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}

	syncToQuickbooks(isSynced: boolean) {
		if (this.invoiceModel.syncToQuickbooks == true && this.invoiceModel.quickbooksId && GlobalsService.company.companyId === 3) {
			this.qbDocNumberSpinnerStatus = "reset";
			this.qbDocNumberChangeDialogRef.showDialog();
		}
		else {
			this.invoiceModel.quickbooksChanged = true;

			if (this.invoiceModel.quickbooksId) {
				this.invoiceModel.quickbooksId = null;
				this.invoiceModel.syncToQuickbooks = false;
				this.invoiceModel.quickbooksDocNumber = null;
			}
			else {
				this.invoiceModel.quickbooksId = null;
				this.invoiceModel.quickbooksDocNumber = null;
				this.invoiceModel.syncToQuickbooks = isSynced;
			}
		}
	}

	async onUpdateQBDocNumber() {
		this.qbDocNumberSpinnerStatus = "spin";

		await SleepService.sleep(500);

		try {
			this.invoicesService.updateQBDocNumber(this.invoiceModel.invoiceId, this.invoiceModel.quickbooksDocNumber);

			this.qbDocNumberSpinnerStatus = "ok";

			await SleepService.sleep(500);

			this.qbDocNumberChangeDialogRef.hideDialog();
		}
		catch {
			this.qbDocNumberSpinnerStatus = "error";
		}
	}

	onCancelQBDocNumber() {
		this.qbDocNumberChangeDialogRef.hideDialog();
	}

	async businessCentralSync() {
		if (this.invoiceModel.syncToBusinessCentral) {
			this.invoiceModel.businessCentralId = null;
			this.invoiceModel.syncToBusinessCentral = false;

		}
		else {
			this.invoiceModel.businessCentralId = this.invoiceModel.businessCentralId;
			this.invoiceModel.syncToBusinessCentral = true;
		}
	}

	async compareLathamBill() {
		await this.compareDialogRef.openDialog(this.invoiceModel);

		this.invoiceModel = await this.invoicesService.getInvoice(this.invoiceModel.invoiceId);


		if (this.onSave)
			this.onSave.emit(this.invoiceModel);
	}

	async addPayment() {
		if (!this.invoiceModel.customer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please select a customer",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (!this.invoiceModel.invoiceNumber) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Invoice # is required",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		const addedPayment = await this.addPaymentDialogRef.showDialog(this.invoiceModel.customer);

		if (addedPayment) {
			const newAppliedPayment = new AppliedPaymentModel();
			newAppliedPayment.uuid = UtilsService.newGuid();
			newAppliedPayment.paymentId = addedPayment.paymentId;
			newAppliedPayment.invoiceId = this.invoiceModel.invoiceId;
			newAppliedPayment.appliedAmount = 0;
			newAppliedPayment.payment = await this.paymentsService.getPayment(addedPayment.paymentId)

			if (newAppliedPayment.payment.unappliedAmount > this.invoiceModel.outstandingBalance)
				newAppliedPayment.appliedAmount = this.invoiceModel.outstandingBalance;
			else
				newAppliedPayment.appliedAmount = newAppliedPayment.payment.unappliedAmount;

			this.invoiceModel.appliedPayments.push(newAppliedPayment);

			this.recalcAppliedPayment(newAppliedPayment);
			//this.invoiceModel = await this.invoicesService.updateInvoice(this.invoiceModel);
		}
	}

	async payment() {
		if (!this.invoiceModel.customer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please select a customer",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (!this.invoiceModel.invoiceNumber) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Invoice # is required",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		this.invoiceModel = await this.invoicesService.updateInvoice(this.invoiceModel);

		const creditCardProcessPaymentModel = new CreditCardProcessPaymentModel();
		creditCardProcessPaymentModel.invoiceId = this.invoiceModel.invoiceId;
		creditCardProcessPaymentModel.amount = this.invoiceModel.outstandingBalance;
		creditCardProcessPaymentModel.invoiceNumber = this.invoiceModel.invoiceNumber;
		creditCardProcessPaymentModel.poNumber = this.invoiceModel.purchaseOrderNumber;
		this.creditCardDialogRef.openDialog(this.invoiceModel.customer, creditCardProcessPaymentModel, this.invoiceModel.selectedCreditCardPaymentTypeId);
	}

	onPaymentTypesChanged(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel[]) {
		const defaultCard = creditCardPaymentTypeModel.find(x => x.isDefault === true);
		if (defaultCard)
			this.invoiceModel.customer.cardOnFile = defaultCard.displayText;
	}

	onInventoryWarehouseSelect(inventoryWarehouseDropDown: IDropdownModel) {
		this.invoiceModel.inventoryWarehouseId = null;
		this.invoiceModel.inventoryWarehouseName = null;

		if (inventoryWarehouseDropDown) {
			this.invoiceModel.inventoryWarehouseId = inventoryWarehouseDropDown.id;
			this.invoiceModel.inventoryWarehouseName = inventoryWarehouseDropDown.text;
		}
	}

	onApproved(paymentApprovedModel: ICreditCardPaymentApprovedModel) {
		if (paymentApprovedModel.creditCardTransactionLogModel.result === "Approved") {
			this.invoiceModel.preAuthPaymentId = paymentApprovedModel.creditCardTransactionLogModel.refNum;
			this.invoiceModel.preAuthMessage = paymentApprovedModel.creditCardTransactionLogModel.preAuthMessage;

			if (!this.invoiceModel.appliedPayments)
				this.invoiceModel.appliedPayments = [];

			if (paymentApprovedModel.appliedPayment)
				this.invoiceModel.appliedPayments = [...this.invoiceModel.appliedPayments, paymentApprovedModel.appliedPayment];

			if (this.invoiceModelChange)
				this.invoiceModelChange.emit(this.invoiceModel);

			if (this.onSave)
				this.onSave.emit(this.invoiceModel);
		}
	}

	isValid(): boolean {
		if (!this.invoiceModel.customer)
			return false;

		if (!this.invoiceModel.invoiceNumber)
			return false;

		if (!this.invoiceModel.shippingAddress.address1)
			return false;

		//if (this.useInventory && !this.invoiceModel.inventoryWarehouseId) {
		//	this.hasInventoryItems = (this.invoiceModel.lineItems.findIndex(x => x.itemInventoryType === 1) >= 0)
		//	if (this.hasInventoryItems)
		//		return false;
		//}

		//if (this.useInventory) {
		//	if (this.inventoryLineItemsRef.isValid() === false) {
		//		this.tabIndex = 1;
		//		return false;
		//	}
		//}

		return true;
	}

	async onRefreshPricing() {
		this.invoiceModel = await this.invoicesService.refreshPricing(this.invoiceModel);
	}

	async saveInvoice() {
		try {
			this.isSubmitted = true;

			if (this.isValid() === false) {
				this.spinnerStatus = "error";
				return;
			}

			if (this.invoiceModel.syncToQuickbooks && (this.invoiceModel.quickbooksId ?? '') === '' && GlobalsService.company.quickbooksOnlineEnabled && (this.invoiceModel.customer.quickbooksId ?? '') === '') {
				await Swal.fire({
					icon: 'warning',
					title: 'Customer not synced to QB',
					text: "This customer has not been synced to Quickbooks.  Please sync the customer before syncing the invoice.",
					confirmButtonColor: '#007bff',
					heightAuto: false
				});
			}

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);

			if (this.useInventory && this.invoiceModel.useDefaultInventory) {
				this.invoiceModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.invoiceModel);
			}
			if (this.invoiceModel.inventoryLineItems.findIndex(x => (x.inventoryItemLocationId == null && (x.inventoryItemLocations?.length ?? 0) > 0)) > 0) {
				this.spinnerStatus = "error";
				return;
			}

			if (this.useJellyFishOrdering && this.invoiceModel.jellyFishOrderSpecsModel) {
				this.invoiceModel.jellyFishOrderSpecsModel = await this.jellyFishService.saveOrderSpecs(this.invoiceModel.jellyFishOrderSpecsModel);
				this.invoiceModel.jellyFishOrderSpecsId = this.invoiceModel.jellyFishOrderSpecsModel.jellyFishOrderSpecsId;
			}


			this.invoiceModel = await this.invoicesService.updateInvoice(this.invoiceModel);

			if (this.onSave)
				this.onSave.emit(this.invoiceModel);

			this.spinnerStatus = "ok";
			this.hasInventoryItems = false;
			this.isSubmitted = false;
		}
		catch (err) {
			this.spinnerStatus = "error";
		}
	}

	cancelInvoice() {
		if (this.onCancel)
			this.onCancel.emit();
	}

	duplicateInvoice() {
		this.onDuplicate.emit();
	}

	async onChangeAppliedAmount(appliedPayment: IAppliedPaymentModel) {
		await SleepService.sleep();
		this.recalcAppliedPayment(appliedPayment);
	}

	async printAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const url = await this.paymentsService.generatePaymentReceiptPDF(appliedPayment.payment.paymentId);
		w.location.href = url;
	}

	async emailAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		this.slickScreenBlockerService.block();

		try {
			const emailerModel = await this.paymentsService.preparePaymentReceiptEmail(appliedPayment.payment);
			this.emailerDialogRef.showDialog(emailerModel);
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}

	async removeAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		this.invoiceModel.appliedPayments = this.invoiceModel.appliedPayments.filter(x => x.uuid !== appliedPayment.uuid);
		const paymentListViewModel = await this.paymentsService.getPaymentListView(appliedPayment.paymentId)
		this.unappliedPayments.unshift(paymentListViewModel)
	}

	async addAppliedPayment(unappliedPayment: IPaymentListViewModel) {
		const newAppliedPayment = new AppliedPaymentModel();
		newAppliedPayment.uuid = UtilsService.newGuid();
		newAppliedPayment.paymentId = unappliedPayment.paymentId;
		newAppliedPayment.invoiceId = this.invoiceModel.invoiceId;
		newAppliedPayment.appliedAmount = 0;
		newAppliedPayment.payment = await this.paymentsService.getPayment(unappliedPayment.paymentId)

		if (newAppliedPayment.payment.unappliedAmount > this.invoiceModel.outstandingBalance)
			newAppliedPayment.appliedAmount = this.invoiceModel.outstandingBalance;
		else
			newAppliedPayment.appliedAmount = newAppliedPayment.payment.unappliedAmount;

		this.invoiceModel.appliedPayments.push(newAppliedPayment);

		this.recalcAppliedPayment(newAppliedPayment);

		this.unappliedPayments = this.unappliedPayments.filter(x => x.paymentId !== newAppliedPayment.payment.paymentId);
	}

	async createJellyFishOrder() {
		try {
			let itemsToSend = this.invoiceModel.lineItems.filter(x => x.sendToManufacturer && x.isParentItem)
			if (itemsToSend.length === 0) {
				await Swal.fire({
					icon: 'warning',
					title: 'Oops',
					text: 'Cannot create order because all line items are unchecked',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});
				return;
			}

			await this.saveInvoice();

			const jellyFishCustomer = await this.jellyFishService.getJellyFishCustomer();
			// we need them to be able to choose the card they want to be charged
			const paymentTypeId = await this.creditCardSelectorDialogRef.openDialog(jellyFishCustomer);

			if (!paymentTypeId) {
				return;
			}

			this.invoiceModel.selectedCreditCardPaymentTypeId = paymentTypeId;

			this.slickScreenBlockerService.block();
			this.invoiceModel = await this.jellyFishService.createOrderFromInvoice(this.invoiceModel);
			this.slickScreenBlockerService.unblock();
			this.slickToastService.showSuccess("Order Created");
		}
		catch {
			this.slickScreenBlockerService.unblock();
			this.slickToastService.showDanger("Failed to create order");
		}
	}

	onLineItemsChanged(lineItems: ILineItemModel[]) {
		this.invoiceModel.flagSaveForTaxUpdate = true;
		this.invoiceModel.lineItems = [...lineItems];
	}

	private recalcAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		appliedPayment.appliedAmount = parseFloat(appliedPayment.appliedAmount.toString());
		appliedPayment.appliedAmount = UtilsService.round(appliedPayment.appliedAmount);

		const invoiceAppliedPayment = this.invoiceModel.appliedPayments.find(x => x.uuid === appliedPayment.uuid);
		invoiceAppliedPayment.appliedAmount = appliedPayment.appliedAmount;
		const totalAppliedAmount = this.invoiceModel.appliedPayments.reduce((appliedAmt, ap) => (appliedAmt += ap.appliedAmount), 0);
		this.invoiceModel.outstandingBalance = this.invoiceModel.invoiceTotal - totalAppliedAmount;

		const additionalAppliedPayments = this.invoiceModel.appliedPayments.filter(x => x.uuid !== appliedPayment.uuid);

		this.invoiceModel.appliedPaymentsTotal = additionalAppliedPayments.reduce((appliedAmt, ap) => (appliedAmt += ap.appliedAmount), 0);
	}

	private async refreshJobPhotos() {
		this.jobPhotos = await this.jobsService.getJobPhotos(this.invoiceModel.jobId);

		if (this.jobPhotos?.length > 0 && this.invoiceModel.selectedJobPhotoDocumentIds?.length > 0) {
			this.jobPhotos.forEach(p => {
				const isChecked = this.invoiceModel.selectedJobPhotoDocumentIds.find(x => x === p.documentId);
				if (isChecked)
					p.isChecked = true;
			});
		}

	}

}