import { OnInit, Component, ViewChild } from '@angular/core';
import { GlobalsService, UsersService, OpenHoursDayService, HttpService } from "@services";
import { SlickInitService, SlickInitParams, SlickDialogComponent } from '@slick-components';
import { Subscription } from 'rxjs';
import { ChatStore, TextChatStore, TimeclockStore, KeepAliveStore } from '@stores';
import { ChatHubService, SignalRMessageHubService, TextChatHubService } from "@signalr"
import { NavigationStart, Router } from '@angular/router';
import { VersionReleaseDialogComponent } from "@shared-components/version-release-dialog"
import { MissedClockOutDialogComponent } from "@shared-components/missed-clock-out-dialog/missed-clock-out-dialog.component"
import { MissedClockInDialogComponent } from "@shared-components/missed-clock-in-dialog"

import * as moment from 'moment';
import { DevTraceService } from '@services/utils/dev-trace.service';


@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
	providers: [OpenHoursDayService]
})
export class AppComponent implements OnInit {
	@ViewChild("dialogRef", { static: true }) dialogRef: VersionReleaseDialogComponent;
	@ViewChild("missedClockOutDialogRef", { static: true }) missedClockOutDialogRef: MissedClockOutDialogComponent;
	@ViewChild("missedClockInDialogRef", { static: true }) missedClockInDialogRef: MissedClockInDialogComponent;

	skeditPay: boolean = GlobalsService.company?.useSkeditPay ?? false;
	isLoggedIn: boolean = false;
	isOnlinePayments: boolean;
	chatType: string;
	chatType$: Subscription;
	showVersionRelease: boolean = false;

	constructor(private userService: UsersService,
		private chatStore: ChatStore,
		private devTracService: DevTraceService,
		private httpService: HttpService,
		private textChatStore: TextChatStore,
		private timeclockStore: TimeclockStore,
		private chatHub: ChatHubService,
		private signalRMessageHub: SignalRMessageHubService,
		private textChatHub: TextChatHubService,
		private openHoursDayService: OpenHoursDayService,
		private keepAliveStore: KeepAliveStore,
		router: Router) {
		this.isLoggedIn = GlobalsService.isLoggedIn;

		const slickInitParams = new SlickInitParams();
		SlickInitService.init(slickInitParams);

		this.isOnlinePayments = GlobalsService.isOnlinePayments;

		if (this.isOnlinePayments) {
			router.events.subscribe(async (event) => {
				if (event instanceof NavigationStart) {
					if (event.url.indexOf('/online-payments') !== 0 && event.url.indexOf('/online-batch-payments') !== 0)
						window.location.href = "/#/online-payments";
				}
			});

			return;
		}

		// This code will trap all buttons and prevent the user from double clicking
		document.addEventListener('click', (e: MouseEvent) => {
			let buttonEl: HTMLButtonElement;

			// Go 3 elements deep to see if this is a button.  
			// The first element is most likely going to be the text of the button
			const target = <HTMLElement>e.target;
			if (target.nodeName === 'BUTTON')
				buttonEl = <HTMLButtonElement>target;

			const parentTarget = <HTMLElement>((target === null) ? null : target.parentElement);
			if (parentTarget && parentTarget.nodeName === 'BUTTON')
				buttonEl = <HTMLButtonElement>parentTarget;

			const grandparentTarget = <HTMLElement>((parentTarget === null) ? null : parentTarget.parentElement);
			if (grandparentTarget && grandparentTarget.nodeName === 'BUTTON')
				buttonEl = <HTMLButtonElement>grandparentTarget;

			if (buttonEl) {
				if (buttonEl.hasAttribute("disabled") ||
					buttonEl.hasAttribute("allowDoubleClick"))
					return;

				setTimeout(() => {
					if (buttonEl.querySelector(".slick-button-spinner_error"))
						return;

					buttonEl.setAttribute("disabled", 'disabled');
				});

				setTimeout(() => {
					// If this has a slick-button-spinner, and the spinner is expanded, let the spinner re-enable
					if (!buttonEl.classList.contains("expand-button-spinner"))
						buttonEl.removeAttribute("disabled")
				}, 600);
			}

		});
	}

	async ngOnInit() {
		if (GlobalsService.isLoggedIn === true) {
			try {
				let lastBackgroundServiceCheck = localStorage.getItem("LAST_WINAPP_CHECK") ?? null;
				if (!lastBackgroundServiceCheck || moment(new Date(lastBackgroundServiceCheck)) < moment()) {
					await this.httpService.get("/Winapp/CheckWinappIsRunning");
					localStorage.setItem("LAST_WINAPP_CHECK", moment().add(1, "hour").toDate().toString())

				}
			}
			catch (ex) {
				this.devTracService.addTrace("Winapp check is breaking");
			}

			this.signalRMessageHub.init();

			if (GlobalsService.userInfo.showOnChat === true) {
				this.chatHub.init();
				this.keepAliveStore.start();
				this.chatType = this.chatStore.chatType;
				this.chatType$ = this.chatStore.chatTypeStore.subscribe(chatType => this.chatType = chatType);
			}

			if (GlobalsService.company.useTextChat === true) {
				this.textChatHub.init();
				this.textChatStore.init(false);
			}

			this.timeclockStore.init();

			if (GlobalsService.userInfo.lastSeenVersion != GlobalsService.appVersion) {
				if (GlobalsService.userInfo.lastSeenVersion === "0")
					await this.userService.updateLastSeenVersion(GlobalsService.appVersion);
				else {
					await this.dialogRef.showDialog();
					await this.userService.updateLastSeenVersion(GlobalsService.appVersion);
				}
			}

			//check to see if user missed a clock out
			// kyle wants techs to not be able to clock in/out anywhere but the hamburger menu clock in button
			if (GlobalsService.lastTimeclockModel && !((GlobalsService.userInfo.roleTypeId === 4 || GlobalsService.userInfo.roleTypeId === 5 ||
				GlobalsService.userInfo.roleTypeId === 6) && GlobalsService.company.companyId === 1)) {
				if (!GlobalsService.lastTimeclockModel.clockOut && moment().diff(moment(GlobalsService.lastTimeclockModel.clockIn), 'hours') > 12 &&
					moment() > moment(GlobalsService.lastTimeclockModel.clockIn).startOf('day').add(1, 'day').add(5, 'hours'))
					await this.missedClockOutDialogRef.showDialog();
			}

			//check to see if user is not time clock exempt and is not already clocked in, dont show if we are on dev
			if (!GlobalsService.userInfo.timeClockExempt && !GlobalsService.lastTimeclockModel && !GlobalsService.isDebug) {
				// office users
				let dailyHours = await this.openHoursDayService.getOpenHoursForDate(moment().toDate());
				if (GlobalsService.userInfo.roleTypeId === 1 || GlobalsService.userInfo.roleTypeId === 2 ||
					GlobalsService.userInfo.roleTypeId === 3 || GlobalsService.userInfo.roleTypeId === 7) {
					if (!dailyHours.IsOfficeClosed) {
						if (moment().toDate() > moment(dailyHours.officeOpenHour, 'h:mm a').add(15, 'minutes').toDate() &&
							moment().toDate() < moment(dailyHours.officeCloseHour, 'h:mm a').toDate())
							this.missedClockInDialogRef.showDialog();
					}
				}

				// service users
				// kyle wants techs to not be able to clock in/out anywhere but the hamburger menu clock in button
				if (GlobalsService.company.companyId !== 1) {
					if (GlobalsService.userInfo.roleTypeId === 4 || GlobalsService.userInfo.roleTypeId === 5 ||
						GlobalsService.userInfo.roleTypeId === 6) {
						if (!dailyHours.IsServiceClosed) {
							if (moment().toDate() > moment(dailyHours.serviceOpenHour, 'h:mm a').add(15, 'minutes').toDate() &&
								moment().toDate() < moment(dailyHours.serviceCloseHour, 'h:mm a').toDate())
								this.missedClockInDialogRef.showDialog();
						}
					}
				}
			}
		}
	}
}