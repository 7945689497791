<slick-dialog key="DIALOG_CREDIT_CARD_EDIT"
			  [header]="isEditDialog ? 'Edit Payment Type' : 'Create Payment Type'"
			  width="620"
			  #creditCardEditDialogRef>
    <div class="credit-card-div"
         *ngIf="creditCardPaymentType && paymentForm">
        <form [formGroup]="paymentForm"
              novalidate
              class="panel panel-default">
            <div class="panel-heading ms-3 me-3 mb-3">

                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label> Card description</label>
                        <input type="text"
                               class="form-control"
                               formControlName="paymentDescription" />
                    </div>
                </div>

                <div class="row ">
                    <div class="col-md-12 mb-2"
                         *ngIf="!isEditDialog">
                        <label> Card number</label>
                        <input class="form-control"
                               formControlName="accountNumber"
                               type="tel"
                               autocomplete="cc-number"
                               ccNumber
                               placeholder="•••• •••• •••• ••••"
                               [slick-validation-indicator]="paymentForm.controls.accountNumber.valid"
                               slickValidationType="error"
                               [ngClass]="{'error-input':submitted && !paymentForm.controls.accountNumber.valid}" />
                    </div>
                    <div class="col-md-12 mt-2"
                         *ngIf="isEditDialog">
                        <label class="mb-2"> Card number</label>
                        <span class="card-last-four">
                            <i *ngIf="creditCardPaymentType.paymentType && creditCardPaymentType.paymentType.toLowerCase().indexOf('visa') > -1"
                               class='fab fa-cc-visa'></i>
                            <i *ngIf="creditCardPaymentType.paymentType && creditCardPaymentType.paymentType.toLowerCase().indexOf('mastercard') > -1"
                               class='fab fa-cc-mastercard'></i>
                            <i *ngIf="creditCardPaymentType.paymentType && creditCardPaymentType.paymentType.toLowerCase().indexOf('discover') > -1"
                               class='fab fa-cc-discover'></i>
                            <i *ngIf="creditCardPaymentType.paymentType && creditCardPaymentType.paymentType.toLowerCase().indexOf('american express') > -1"
                               class='fab fa-cc-amex'></i>
                            •••• •••• •••• {{ creditCardPaymentType.accountNumberLastFour }}
                        </span>
                    </div>
                    <div class="col-md-12 pt-1 pb-1"
                         *ngIf="submitted && !isEditDialog && !paymentForm.controls.accountNumber.valid">
                        <form-error>Credit card number is not valid.</form-error>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        <label>Expiration Date</label>
                        <input type="tel"
                               autocomplete="cc-exp"
                               class="form-control"
                               formControlName="expDate"
                               placeholder="MM / YYYY"
                               ccExp
                               [slick-validation-indicator]="paymentForm.controls.expDate.valid"
                               slickValidationType="error"
                               [ngClass]="{'error-input': submitted && !paymentForm.controls.expDate.valid}" />
                        <div class="pt-1 pb-1" *ngIf="submitted && !paymentForm.controls.expDate.valid">
                            <form-error>Expiration date is not valid.</form-error>
                        </div>
                    </div>
                
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        <label>CVC</label>
                        <input type="tel"
                               autocomplete="off"
                               class="form-control"
                               formControlName="cvc"
                               placeholder="•••"
                               ccCvc
                               [slick-validation-indicator]="paymentForm.controls.cvc.valid"
                               slickValidationType="error"
                               [ngClass]="{'error-input': submitted && !paymentForm.controls.cvc.valid}" />
                        <div class="pt-1 pb-1" *ngIf="submitted && !paymentForm.controls.cvc.valid">
                            <form-error>CVC number is not valid.</form-error>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="ms-3 me-3">

            <div>
                <label>Address Search</label>
                <input type="search"
                       class="form-control"
                       name="addressSearch"
                       [slick-google-address-search]
                       (onGoogleAddressSelect)="
                            onGoogleMapAddressSelect($event)
                        " />
            </div>
            <div class="mt-2 customer-info form-group">
                <div class="width-full">
                    <label>Address 1</label>
                    <input class="form-control"
                           [(ngModel)]="creditCardPaymentType.addressModel.address1" />
                </div>
            </div>

            <div class="customer-info form-group">
                <div class="width-full">
                    <label>Address 2</label>
                    <input class="form-control"
                           [(ngModel)]="creditCardPaymentType.addressModel.address2" />
                </div>
            </div>

            <div class="d-flex row form-group">
                <div class="col-12 col-lg-6 col-xl">
                    <label>City</label>
                    <input class="form-control"
                           [(ngModel)]="creditCardPaymentType.addressModel.city" />
                </div>

                <div class="col-12 col-lg-6 col-xl-2">
                    <div *ngIf="!isCanada">
                        <label>State</label>
                        <input class="form-control"
                               [(ngModel)]="creditCardPaymentType.addressModel.state" />
                    </div>

                    <div *ngIf="isCanada">
                        <label>Province</label>
                        <input class="form-control"
                               [(ngModel)]="creditCardPaymentType.addressModel.state" />
                    </div>
                </div>

                <div class="col-12 col-lg-6 col-xl-2">
                    <label>Zipcode</label>
                    <input class="form-control"
                           [(ngModel)]="creditCardPaymentType.addressModel.zipcode" />
                </div>

                <div class="col-12 col-lg-6 col-xl-3">
                    <label>Country</label>
                    <slick-drop-down [(ngModel)]="creditCardPaymentType.addressModel.country"
                                     [items]="countries"></slick-drop-down>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12 pad-adjust">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   checked
                                   class="text-muted small-font"
                                   [(ngModel)]="creditCardPaymentType.isDefault"> Default Card
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <slick-dialog-footer>
        <div *ngIf="isEditDialog">
            <div class="col-12">
                <div style="float: right">
                    <div class="btn-group">
                        <button type="button"
                                class="btn btn-outline-danger"
                                (click)="delete()"
                                style="width: 140px;"
                                [slick-button-spinner]="deleteSpinnerStatus">
                            <i class="bi bi-trash"></i>
                            Delete
                        </button>
                    </div>
                </div>
                <div style="float: left;">
                    <button type="submit"
                            class="btn btn-outline-success me-3"
                            style="width: 140px;"
                            (click)="onSubmit(paymentForm)"
                            [slick-button-spinner]="saveSpinnerStatus">
                        <i class="far fa-check-circle"></i> Save
                    </button>
                    <button type="button"
                            class="btn btn-outline-danger"
                            style="width: 140px;"
                            (click)="cancel()">
                        <i class="bi bi-x-circle"></i> Cancel
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="!isEditDialog">
            <div class="col-12">
                <div class="btn-group me-3">
                    <button type="button"
                            class="btn btn-outline-success"
                            style="width: 140px;"
                            (click)="onSubmit(paymentForm)"
                            [slick-button-spinner]="saveSpinnerStatus">
                        <i class="far fa-check-circle"></i>
                        Save
                    </button>
                </div>
                <div class="btn-group">
                    <button type="button"
                            class="btn btn-outline-danger"
                            style="width: 140px;"
                            (click)="cancel()">
                        <i class="bi bi-x-circle"></i> Cancel
                    </button>
                </div>

            </div>
        </div>
    </slick-dialog-footer>

</slick-dialog>

<slick-confirm-dialog #deleteCardRef>
	<div>
		Are you sure you want to delete this card?
	</div>
</slick-confirm-dialog>