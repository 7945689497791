<div class="col mt-3 ps-2">
	<ng-template [ngIf]="creditCardPaymentTypes">
		<article class="credit-card-info" *ngFor="let creditcard of creditCardPaymentTypes">
			<button type="button" class="card-ui btn btn-outline-primary" [ngClass]="{ 'btn-defaultCard': creditcard.isDefault }" (click)="selectPaymentType(creditcard); editPaymentType(creditcard);">
				<div class="col-12 ps-0 pe-0">
					<span class="col-10 ps-0 pe-0 card-description">{{ creditcard.paymentDescription}}</span>
					<span class="col-2 ps-0 pe-0 card-icon">
						<i *ngIf="creditcard.paymentType.toLowerCase().indexOf('visa') > -1" class='fab fa-cc-visa'></i>
						<i *ngIf="creditcard.paymentType.toLowerCase().indexOf('mastercard') > -1" class='fab fa-cc-mastercard'></i>
						<i *ngIf="creditcard.paymentType.toLowerCase().indexOf('discover') > -1" class='fab fa-cc-discover'></i>
						<i *ngIf="creditcard.paymentType.toLowerCase().indexOf('american express') > -1" class='fab fa-cc-amex'></i>
					</span>
				</div>
				<div class="col-12 ps-0 pe-0 card-number">
					XXXX XXXX XXXX {{ creditcard.accountNumberLastFour }}
				</div>
				<div class="col-12 ps-0 pe-0 d-flex justify-content-between">
					<div style="text-align: left" *ngIf="creditcard.isDefault">
						Default Card
					</div>
					<div style="text-align: right" *ngIf="creditcard.isDefault">
						{{ creditcard.expDate }}
					</div>
				</div>
				<div style="text-align: right" *ngIf="!creditcard.isDefault">
					{{ creditcard.expDate }}
				</div>
			</button>
		</article>
	</ng-template>

	<article>
		<button type="button" class="card-ui btn btn-outline-primary add-new-payment-holder" (click)="createNewPaymentType()">
			<div class="add-new-payment"><i class="fa fa-plus"></i> Add Card</div>
		</button>
	</article>
</div>

<credit-card-edit-dialog #creditCardEditDialog (onChange)="creditCardEditDialogChanges()"></credit-card-edit-dialog>