import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { DevLogsStore } from '@stores';
import { IAppointmentModel } from '@models';
import { SQLiteService } from '@sqlite';
import { DevTraceService } from '@services/utils/dev-trace.service';
import * as moment from "moment";

interface IAppointmentRecord {
	appointmentId: number;
	appointmentData: string;
}

@Injectable()
export class AppointmentStorageService {
	private db!: SQLiteDBConnection;

	readonly databaseName: string = 'appointments';

	constructor(private devLogsStore: DevLogsStore,
		private devTrace: DevTraceService,
		private sqliteService: SQLiteService) {
	}

	async initializeDatabase(): Promise<void> {
		try {
			await this.sqliteService
				.addUpgradeStatement({
					database: this.databaseName,
					upgrade: this.appointmentsUpdates
				});

			// create and/or open the database
			const loadToVersion = this.appointmentsUpdates[this.appointmentsUpdates.length - 1].toVersion;
			this.db = await this.sqliteService.openDatabase(this.databaseName, loadToVersion);
			this.sqliteService.setVersion(this.databaseName, loadToVersion);
			this.devLogsStore.addMessage(`Init ${this.databaseName} database to version ${loadToVersion}`);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async deleteDatabase(): Promise<void> {
		await this.sqliteService.deleteDatabase(this.databaseName);
		this.devLogsStore.addMessage(`Deleted ${this.databaseName}`);
	}

	async updateAppointment(appointmentId: number, appointment: IAppointmentModel) {
		try {
			//this.devLogsStore.addMessage(`Retrieving all appointments for cleanup`);

			// Retrieve all appointments
			const appointments: IAppointmentRecord[] = (await this.db.query(`SELECT * FROM appointments;`)).values as IAppointmentRecord[];

			const oneWeekAgo = moment().subtract(7, 'days').startOf('day').toISOString();
			const oneWeekAhead = moment().add(7, 'days').endOf('day').toISOString();

			// Collect IDs of appointments to be deleted
			const idsToDelete = [];

			for (const appt of appointments) {
				const appointmentJSON = SQLiteService.decodeJSON(appt.appointmentData)
				const parsedData = JSON.parse(appointmentJSON);
				const scheduledDateTime = parsedData.scheduledDateTime;

				if (scheduledDateTime < oneWeekAgo || scheduledDateTime > oneWeekAhead) {
					idsToDelete.push(appt.appointmentId);
				}
			}

			if (idsToDelete.length > 0) {
				// Use a single DELETE query with an IN clause
				const placeholders = idsToDelete.map(() => '?').join(',');
				await this.db.run(`DELETE FROM appointments WHERE appointmentId IN (${placeholders});`, idsToDelete);
				this.devLogsStore.addMessage(`Deleted appointments with IDs: ${idsToDelete.join(', ')}`);
			}

			//this.devLogsStore.addMessage(`Adding appointment record for appointmentId ${appointmentId}`);

			const appointmentEncoded = SQLiteService.encodeJSON(appointment);

			// Update or insert the current appointment
			await this.db.run(`DELETE FROM appointments WHERE appointmentId = ?;`, [appointmentId]);

			const sql = `INSERT INTO appointments (appointmentId, appointmentData) VALUES (?, ?);`;
			await this.db.run(sql, [appointmentId, appointmentEncoded]);
		} catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async deleteUnnecessaryAppointments() {
		// Retrieve all appointments
		const appointments: IAppointmentRecord[] = (await this.db.query(`SELECT * FROM appointments;`)).values as IAppointmentRecord[];

		const oneWeekAgo = moment().subtract(7, 'days').startOf('day').toISOString();
		const oneWeekAhead = moment().add(7, 'days').endOf('day').toISOString();

		// Collect IDs of appointments to be deleted
		const idsToDelete = [];

		for (const appt of appointments) {
			const appointmentJSON = SQLiteService.decodeJSON(appt.appointmentData)
			const parsedData = JSON.parse(appointmentJSON);
			const scheduledDateTime = parsedData.scheduledDateTime;

			if (scheduledDateTime < oneWeekAgo || scheduledDateTime > oneWeekAhead) {
				idsToDelete.push(appt.appointmentId);
			}
		}

		if (idsToDelete.length > 0) {
			// Use a single DELETE query with an IN clause
			const placeholders = idsToDelete.map(() => '?').join(',');
			await this.db.run(`DELETE FROM appointments WHERE appointmentId IN (${placeholders});`, idsToDelete);
			this.devLogsStore.addMessage(`Deleted appointments with IDs: ${idsToDelete.join(', ')}`);
		}
	}


	async updateAppointments(appointments: IAppointmentModel[]) {
		try {
			await this.deleteUnnecessaryAppointments();

			let encodedAppts = [];

			let idsToDelete = [];

			for (const appointment of appointments) {
				const appointmentEncoded = SQLiteService.encodeJSON(appointment);
				encodedAppts.push({ appointmentId: appointment.appointmentId, appointmentData: appointmentEncoded });
				idsToDelete.push(appointment.appointmentId);
			}

			// Update or insert the current appointment
			if (idsToDelete.length > 0) {
				// Use a single DELETE query with an IN clause
				const placeholders = idsToDelete.map(() => '?').join(',');
				await this.db.run(`DELETE FROM appointments WHERE appointmentId IN (${placeholders});`, idsToDelete);
				//this.devLogsStore.addMessage(`Deleted appointments with IDs: ${idsToDelete.join(', ')}`);
			}

			// Prepare the base SQL for bulk insert
			let sql = `INSERT INTO appointments (appointmentId, appointmentData) VALUES `;

			// Collect the placeholders and the values
			const values = [];
			for (const appt of encodedAppts) {
				values.push(`(${appt.appointmentId}, ?)`);
			}

			// Join the values into the SQL statement
			sql += values.join(', ') + ';';

			// Extract all appointment data to be inserted
			const data = encodedAppts.map(appt => appt.appointmentData);

			// Execute the query with all data
			await this.db.run(sql, data);


		} catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}

	}

	async getAppointment(appointmentId: number): Promise<IAppointmentModel> {
		try {
			const appointmentRecords: IAppointmentRecord[] = (await this.db.query(`SELECT * FROM appointments where appointmentId='${appointmentId}';`)).values as IAppointmentRecord[];

			if ((appointmentRecords?.length ?? 0) === 0)
				return null;

			const appointmentEncoded = appointmentRecords[0].appointmentData;
			const appointmentJSON = SQLiteService.decodeJSON(appointmentEncoded)
			const appointment: IAppointmentModel = JSON.parse(appointmentJSON);

			//this.devLogsStore.addMessage(`Returning JSON ${appointmentJSON}`);

			return appointment;
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	readonly appointmentsUpdates = [
		{
			toVersion: 1,
			statements: [
				`
CREATE TABLE IF NOT EXISTS appointments(
    appointmentId number PRIMARY KEY,
    appointmentData TEXT NULL
);`
			]
		},
		/* add new statements below for next database version when required*/
		/*
		{
		toVersion: 2,
		statements: [
			`ALTER TABLE users ADD COLUMN email TEXT;`,
		]
		},
		*/
	]
}


