import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from "@angular/core";
import { LookupService, LineItemsService, ItemsService, FunctionLockService } from "@services";
import { IQuickAddCategoryModel, IQuickAddCategoryItemModel, ILineItemModel, LineItemTypes, IItemModel } from "@models";
import { SlickDialogComponent } from "@slick-components";

@Component({
	selector: "quick-adds",
	templateUrl: "./quick-adds.component.html",
	styleUrls: ["./quick-adds.component.css"],
	providers: [LookupService, LineItemsService, ItemsService, FunctionLockService]
})

export class QuickAddsComponent implements OnInit {
	@Input() lineItems: ILineItemModel[];
	@Output() lineItemsChange: EventEmitter<ILineItemModel[]> = new EventEmitter();
	@Input() customerId: number;

	@ViewChild("itemDetailDialogRef", { static: true }) itemDetailDialogRef: SlickDialogComponent;

	quickAddCategories: IQuickAddCategoryModel[];
	selectedQuickAddCategory: IQuickAddCategoryModel;
	itemDetail: IItemModel;

	constructor(private readonly lookupService: LookupService,
		private readonly lineItemService: LineItemsService,
		private readonly itemsService: ItemsService,
		private readonly functionLockService: FunctionLockService) {
	}

	ngOnInit(): void {
		this.quickAddCategories = this.lookupService.getQuickAddCategoriesForRole();
	}

	selectCategory(category: IQuickAddCategoryModel) {
		this.selectedQuickAddCategory = category;
	}

	backToCategories() {
		this.selectedQuickAddCategory = null;
	}

	async showItemInfo(e: MouseEvent, selectedItem: IQuickAddCategoryItemModel) {
		e.preventDefault();
		e.stopPropagation();

		this.itemDetail = await this.itemsService.getItem(selectedItem.itemId);

		this.itemDetailDialogRef.showDialog();
	}


	async selectItem(selectedItem: IQuickAddCategoryItemModel) {
		await this.functionLockService.lock("QUICKADDS_SELECT_ITEMS");

		try {
			const existingItems = this.lineItems.filter((item) => {
				return item.sku === selectedItem.sku;
			});

			if (existingItems.length > 0) {
				for (let existing of existingItems) {
					existing.quantity += 1;
				}
			} else {
				const lineItem = await this.lineItemService.getLineItemBySku(selectedItem.sku, (this.customerId || 0));
				lineItem.displayOrder = this.lineItems.filter(x => x.lineItemType === LineItemTypes.LineItem && x.isSystemLineItem === false).reduce((max, li) => li.displayOrder > max ? li.displayOrder : max, 0) + 1;
				lineItem.lineItemType = LineItemTypes.LineItem;
				lineItem.quantity = 1;
				lineItem.sendToManufacturer = true;
				lineItem.taxable = true;
				this.lineItems.push(lineItem);
			}
			this.lineItemsChange.emit(this.lineItems);
		}
		finally {
			await this.functionLockService.release("QUICKADDS_SELECT_ITEMS");
		}
	}

}