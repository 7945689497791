import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IInvoiceModel, IInvoiceListModel, IInvoiceLaborItemModel, IPaymentTermModel, IDropdownModel, IEmailerModel, IInvoiceQCCheckModel, ILineItemModel, IBatchAcceptInvoiceModel, IInventoryLineItemModel, LineItemTypes, InventoryLineItemModel, ITextChatMessageModel, CustomerModel, AddressModel, IAddressModel } from "@models";
import { UtilsService } from "@services/utils/utils.service";
import { GlobalsService } from "@services/utils/globals.service";
import * as moment from "moment";
import { TexterModel } from "../models/texter/texter.model";

@Injectable()
export class InvoicesService {
	constructor(private readonly httpService: HttpService) { }

	async getInvoiceForGridView(invoiceId: number): Promise<IInvoiceListModel> {
		return this.httpService.get("/invoices/getInvoiceForGridView", { invoiceId: invoiceId });
	}

	async getInvoicesForListView(customerId: number, searchString: string, showEstimates: boolean, showSalesOrders: boolean, showInvoices: boolean): Promise<IInvoiceListModel[]> {
		return this.httpService.get(`/invoices/getInvoicesForListView?customerId=${customerId}&searchString=${searchString}&showEstimates=${showEstimates}&showSalesOrders=${showSalesOrders}&showInvoices=${showInvoices}`);
	}

	async getInvoiceForListView(invoiceId: number): Promise<IInvoiceListModel> {
		return this.httpService.get("/invoices/getInvoiceForListView", { invoiceId: invoiceId });
	}

	async getInvoicesForInfiniteScroll(customerId: number, pageCount: number, searchString: string, showEstimates: boolean, showSalesOrders: boolean, showInvoices: boolean): Promise<IInvoiceListModel[]> {
			return this.httpService.get(`/invoices/getInvoicesForInfiniteScroll?customerId=${customerId}&pageCount=${pageCount}&searchString=${searchString}&showEstimates=${showEstimates}&showSalesOrders=${showSalesOrders}&showInvoices=${showInvoices}`);
	}

	async getInvoice(invoiceId: number): Promise<IInvoiceModel> {
		return this.httpService.get("/invoices/getInvoice", { invoiceId: invoiceId });
	}

	async getInvoicesByCustomer(customerId: number, withBalanceDueOnly: boolean = true): Promise<IInvoiceListModel[]> {
		const params = {
			customerId: customerId,
			withBalanceDueOnly: withBalanceDueOnly
		}

		return this.httpService.get("/invoices/getInvoicesByCustomer", params);
	}

	async getInvoiceByJobId(jobId: number): Promise<IInvoiceModel> {
		const params = {
			jobId: jobId || 0
		}
		return this.httpService.get("/invoices/getInvoiceByJobId", params);
	}

	async getInvoiceByAppointmentId(appointmentId: number): Promise<IInvoiceModel> {
		return this.httpService.get("/invoices/getInvoiceByAppointmentId", appointmentId);
	}

	async getNextInvoiceNumber(): Promise<string> {
		const res = await this.httpService.get("/invoices/getNextInvoiceNumber");
		return res.invoiceNumber;
	}

	async updateInvoice(model: IInvoiceModel): Promise<IInvoiceModel> {
		return this.httpService.post("/invoices/updateInvoice", model);
	}

	async refreshPricing(model: IInvoiceModel): Promise<IInvoiceModel> {
		return this.httpService.post("/invoices/refreshPricing", model);
	}

	async linkInvoiceToJob(invoiceId: number, jobId: number): Promise<IInvoiceModel> {
		return this.httpService.patch(`/invoices/linkInvoiceToJob?invoiceId=${invoiceId}&jobId=${jobId}`);
	}

	async linkInvoiceToShippingRecord(invoiceId: number, shippingRecordId: number): Promise<IInvoiceModel> {
		return this.httpService.patch(`/invoices/linkInvoiceToShippingRecord?invoiceId=${invoiceId}&shippingRecordId=${shippingRecordId}`);
	}

	async getOpenInvoices(): Promise<IInvoiceListModel[]> {
		return this.httpService.get(`/invoices/getOpenInvoices`);
	}

	async getNextSeq(invoiceGroupUuid: string): Promise<number> {
		return this.httpService.get('/invoices/getNextSeq', { invoiceGroupUuid });
	}

	async splitInvoice(originalInvoice: IInvoiceModel, newInvoice: IInvoiceModel): Promise<IInvoiceModel> {
		const params = {
			originalInvoice,
			newInvoice
		};
		return this.httpService.post(`/invoices/splitInvoice`, params);
	}

	async deleteInvoice(invoiceId: number): Promise<boolean> {
		return this.httpService.delete("/invoices/deleteInvoice", { invoiceId: invoiceId });
	}

	async updateJobId(invoiceId: number, jobId: number): Promise<boolean> {
		return this.httpService.patch(`/invoices/updateJobId?invoiceId=${invoiceId}`, jobId);
	}

	async prepareInvoiceEmail(model: IInvoiceModel): Promise<IEmailerModel> {
		return this.httpService.post("/invoices/prepareInvoiceEmail", model);
	}

	async prepareInvoiceText(model: IInvoiceModel): Promise<TexterModel> {
		return this.httpService.post("/invoices/prepareInvoiceText", model);
	}
	async addInvoiceEmailSentNote(invoiceId, forBatch): Promise<void> {
		return this.httpService.patch(`/invoices/addInvoiceEmailSentNote?invoiceId=${invoiceId}&forBatch=${forBatch}`);
	}

	async getPaymentTerms(companyId: number = null, includeInactive: boolean = false): Promise<IPaymentTermModel[]> {
		return this.httpService.get("/invoices/getPaymentTerms", { companyId: companyId, includeInactive: includeInactive });
	}

	async getInvoiceLaborItems(): Promise<IInvoiceLaborItemModel[]> {
		return this.httpService.get("/invoices/getInvoiceLaborItems");
	}

	async getInvoicesForDropdown(): Promise<IDropdownModel[]> {
		return this.httpService.get("/invoices/getInvoicesForDropdown");
	}

	async generateInvoicePdf(model: IInvoiceModel, forEmail: boolean = true): Promise<string> {
		return this.httpService.post(`/invoices/generateInvoicePdf?forEmail=${forEmail}`, model);
	}

	async generateWorkOrderPdf(model: IInvoiceModel): Promise<string> {
		return this.httpService.post("/invoices/generateWorkOrderPdf", model);
	}

	async generateInvoicePaymentReceiptPdf(model: IInvoiceModel): Promise<string> {
		return this.httpService.post("/invoices/generateInvoicePaymentReceiptPdf", model);
	}

	async syncQuickbooks(invoiceId: number): Promise<boolean> {
		return this.httpService.patch(`/invoices/syncQuickbooks?invoiceId=${invoiceId}`);
	}

	async unsyncQuickbooks(invoiceId: number): Promise<boolean> {
		return this.httpService.patch(`/invoices/unsyncQuickbooks?invoiceId=${invoiceId}`);
	}

	async syncBusinessCentral(invoiceId: number): Promise<boolean> {
		return this.httpService.patch(`/invoices/syncBusinessCentral?invoiceId=${invoiceId}`);
	}

	async addQCCheck(invoiceId: number): Promise<IInvoiceQCCheckModel> {
		return this.httpService.patch(`/invoices/addQCCheck?invoiceId=${invoiceId}`);
	}

	async duplicateInvoice(invoice: IInvoiceModel): Promise<IInvoiceModel> {
		const newInvoice: IInvoiceModel = <IInvoiceModel>UtilsService.clone(invoice);
		newInvoice.invoiceId = 0;
		newInvoice.jobId = null;
		newInvoice.lathamOrderId = null;
		newInvoice.documentKey = UtilsService.newGuid();
		newInvoice.createdBy = GlobalsService.userInfo.userId;
		newInvoice.purchaseOrderNumber = newInvoice.purchaseOrderNumber + ' (Copy)';
		newInvoice.invoiceNumber = await this.getNextInvoiceNumber();
		if (newInvoice.isQuote) {
			newInvoice.quoteDate = moment().startOf("day").toDate();
		}
		else {
			newInvoice.invoiceDate = moment().startOf("day").toDate();
		}
		newInvoice.quickbooksId = null;
		newInvoice.quickbooksDocNumber = null;
		newInvoice.syncToQuickbooks = false;
		newInvoice.appliedPayments = [];
		newInvoice.acceptedDate = null;
		newInvoice.acceptedIPAddress = null;
		newInvoice.acceptedSignature = null;
		newInvoice.appointmentNotes = [];
		newInvoice.lineItems.forEach(x => {
			x.uuid = UtilsService.newGuid();
		});
		return newInvoice;
	}

	async getNextInvoiceSeq(invoiceGroupUuid: string): Promise<number> {
		return this.httpService.get(`/invoices/getNextInvoiceSeq`, { invoiceGroupUuid });
	}

	async updateQBDocNumber(invoiceId: number, qbDocNumber: string): Promise<void> {
		return this.httpService.patch(`/invoices/updateQBDocNumber?invoiceId=${invoiceId}&qbDocNumber=${qbDocNumber}`);
	}

	async updateJobPhotoCheckChanged(invoiceId: number, documentId: number, isChecked: boolean): Promise<void> {
		return this.httpService.patch(`invoices/updateJobPhotoCheckChanged?invoiceId=${invoiceId}&documentId=${documentId}&isChecked=${isChecked}`);
	}

	async getEstimateForApproval(companyId: number, invoiceId: number): Promise<IInvoiceModel> {
		const params = {
			companyId,
			invoiceId
		}

		return this.httpService.get("/invoices/getEstimateForApproval", params);
	}

	async getEstimateForApprovalByEncryptedLink(linkId: string): Promise<IInvoiceModel> {
		return this.httpService.get(`/invoices/getEstimateForApprovalByEncryptedLink?linkId=${linkId}`);
	}

	async acceptEstimate(model: IInvoiceModel): Promise<IInvoiceModel> {
		return this.httpService.post("/invoices/acceptEstimate", model);
	}

	async printBatchInvoices(estimateIds: number[], uuid: string): Promise<string> {
		const params = {
			estimateIds,
			uuid
		}
		
		return this.httpService.get("/invoices/generateBatchInvoicePdf", params);
	}

	async prepareBatchInvoiceEmail(invoiceIds: number[], uuid: string): Promise<IEmailerModel> {
		const params = {
			invoiceIds,
			uuid,
		}
		return this.httpService.get("/invoices/prepareBatchInvoiceEmail", params);
	}

	async getBatchEstimateForApproval(encryptionLinkId: string): Promise<IBatchAcceptInvoiceModel> {
		
		return this.httpService.get("/invoices/getBatchEstimateForApproval", { encryptionLinkId })
	}

	async acceptBatchEstimates(invoiceIds: number[], signature: string, companyId: number): Promise<void> {
		const params = {
			invoiceIds,
			signature,
			companyId
		}
		return this.httpService.get("/invoices/AcceptBatchEstimates", params );

	}

	async convertBatchToSalesOrder(invoiceIds: number[]): Promise<void> {
		this.httpService.post("/invoices/ConvertBatchToSalesOrder", invoiceIds);
	}

	async convertBatchToInvoice(invoiceIds: number[]): Promise<void> {
		this.httpService.post("/invoices/ConvertBatchToInvoice", invoiceIds);
	}

	async changeBatchInvoiceStatuses(invoiceIds: number[], statusId: number): Promise<boolean> {
		return this.httpService.post(`/invoices/changeBatchInvoiceStatuses?statusId=${statusId}`, invoiceIds);
	}

	syncInventoryFromLineItems(invoiceModel: IInvoiceModel): IInventoryLineItemModel[] {
		if (!invoiceModel.lineItems || invoiceModel.lineItems.length === 0)
			return [];

		if (invoiceModel.useDefaultInventory === false)
			return UtilsService.clone(invoiceModel.inventoryLineItems);

		const returnInventoryLineItems: IInventoryLineItemModel[] = invoiceModel.lineItems
			.filter(x => x.lineItemType === LineItemTypes.LineItem && x.itemInventoryType === 1)
			.map(li => {
				let inventoryLineItem = invoiceModel.inventoryLineItems.find(x => x.uuid === li.uuid);
				if (!inventoryLineItem) {
					inventoryLineItem = new InventoryLineItemModel();
					inventoryLineItem.uuid = li.uuid;
				}

				inventoryLineItem.sendToManufacturer = li.sendToManufacturer;
				inventoryLineItem.itemId = li.itemId;
				inventoryLineItem.sku = li.sku;
				inventoryLineItem.description = li.description;
				inventoryLineItem.quantity = li.quantity;
				inventoryLineItem.cost = li.cost;
				inventoryLineItem.editable = true;
				inventoryLineItem.displayOrder = li.displayOrder;
				if (inventoryLineItem.inventoryWarehouseId !== invoiceModel.inventoryWarehouseId) {
					inventoryLineItem.inventoryWarehouseId = invoiceModel.inventoryWarehouseId;
					inventoryLineItem.inventoryWarehouseName = invoiceModel.inventoryWarehouseName;
					inventoryLineItem.inventoryItemLocationId = null;
					inventoryLineItem.inventoryItemLocationName = null;
				}

				return inventoryLineItem;
			})

		return returnInventoryLineItems;
	}

	mapAddressFromCustomer(customerModel: CustomerModel): IAddressModel {
		let address = new AddressModel();
		address.address1 = customerModel.address1;
		address.address2 = customerModel.address2;
		address.city = customerModel.city;
		address.state = customerModel.state;
		address.zipcode = customerModel.zipcode;
		address.country = customerModel.country;
		return address;
    }
}
